import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "collapse", "icon" ]

  toggle() {
    this.iconTarget.classList.toggle('fa-minus');
    this.iconTarget.classList.toggle('fa-plus');


    this.collapseTarget.classList.toggle('hidden');

  }
}
