// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import { Alert, Toggle, Popover, Modal } from "tailwindcss-stimulus-components"
import Appsignal from "@appsignal/javascript"
import { installErrorHandler } from "@appsignal/stimulus"
import { Autocomplete } from 'stimulus-autocomplete'

const appsignal = new Appsignal({
  key: window._appsignal_frontend_api_key
})

const application = Application.start()
installErrorHandler(appsignal, application)
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

// Enable Stimulus debug mode in development
application.debug = process.env.NODE_ENV === 'development'

// Register all TailwindCSS components used by the app
application.register('alert', Alert)
application.register('toggle', Toggle)
application.register('popover', Popover)
application.register('modal', Modal)

application.register('autocomplete', Autocomplete)
