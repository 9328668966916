import { Controller } from "@hotwired/stimulus";
import Dom from 'helpers/dom';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../tailwind.config';

export default class extends Controller {
  static targets = ['menu', 'searchForm'];

  scrolledContent = false;
  twConfig = resolveConfig(tailwindConfig);

  connect() {
    // Scroll handler.
    this.scrollHandler();
    Dom.addEventListener(`${this.identifier}:scroll`, this.scrollHandler.bind(this), false, window);
  }

  disconnect() {}

  /**
   * Get header height.
   */
  get height() {
    return this.scrolledContent
      ? this.menuTarget.offsetHeight
      : this.element.offsetHeight;
  }

  /**
   * Search: open/perfom handler.
   */
  searchOpen(e) {
    e.preventDefault();

    if ((this.element.hasAttribute('data-searching') && this.element.getAttribute("data-searching") == 'true') || (window.getComputedStyle(this.searchFormTarget).visibility === 'visible')) {
      // Perform search.
      this.searchFormTarget.submit();
    } else {
      // Show search bar.
      const searchOpen = e.target.closest('[data-action$=searchOpen]');

      const leftPos = this.element.offsetWidth - searchOpen.offsetLeft - searchOpen.offsetWidth;
      searchOpen.style.left = `${leftPos}px`;
      searchOpen.classList.add('px-4');

      this.element.setAttribute('data-searching', 'true');

      searchOpen.addEventListener('transitionend', () => {
        this.element.querySelector('#search')?.focus();
      }, { once: true });
    }
  }

  /**
   * Search: close handler.
   */
  searchClose() {
    // Hide search bar.
    const searchOpen = this.element.querySelector('[data-action$=searchOpen]');
    if (searchOpen) {
      searchOpen.style.left = 0;
      searchOpen.classList.remove('px-4');
    }
    this.element.removeAttribute('data-searching');
    const searchInput = this.element.querySelector('#search');
    if (searchInput) {
      searchInput.value = '';
    }
  }

  /**
   * Get cart controller.
   * @return {Controller}
   */
  get cartController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller$="header--cart"]'),
      'header--cart',
    );
  }

  /**
   * Get cart toggle element.
   * @return {Element}
   */
  get cartToggleTarget() {
    return this.element.querySelector('[data-action$="cartToggle"]');
  }

  /**
   * Cart: toggle.
   */
  cartToggle(event) {
    this.cartController.toggle(event);
  }

  /**
   * Check if screen size is small.
   * @return {boolean}
   */
  get isSmallScreen() {
    return window.matchMedia(`(max-width: ${this.twConfig.theme.screens.sm})`).matches;
  }

  scrollHandler() {
    // Fix header menu or cart toggle on top when content scrolled.
    setTimeout(() => {
      this.menuOffsetTop = this.menuOffsetTop || this.menuTarget.offsetTop;
      this.cartToggleTop = this.cartToggleTop
        || (
          this.cartToggleTarget.querySelector('img').offsetTop
          + this.element.children[0].offsetHeight
          - 12
        );

      if (this.isSmallScreen) {
        // Small screen. Header menu fixed.
        this.scrolledContent = document.documentElement.scrollTop > this.menuOffsetTop
          || (parseFloat(document.body.style.top) || 0) !== 0;
        if (this.scrolledContent) {
          document.body.classList.add('header-menu-fixed');
        } else {
          document.body.classList.remove('header-menu-fixed');
        }
      } else {
        // Big screen. Cart toggle fixed.
        this.scrolledContent = document.documentElement.scrollTop > this.cartToggleTop
          || (parseFloat(document.body.style.top) || 0) !== 0;
        if (this.scrolledContent) {
          document.body.setAttribute('data-header-cart-fixed', '');
        } else {
          document.body.removeAttribute('data-header-cart-fixed');
        }

        // Update cart's layer top position when cart open.
        if (this.cartToggleTarget.hasAttribute('data-open')) {
          this.cartController.setTop();
        }
      }
    }, 100);
  }
}
