import { Controller } from "@hotwired/stimulus";
import Dom from 'helpers/dom';
import Spinner from 'helpers/spinner';
import Request from 'helpers/request';

export default class extends Controller {
  static targets = ['launcher', 'address'];

  static values = {
    country: String,
    context: String,
    entity: String,
    inputName: String,
    inputTarget: String,
    selectedAddressId: Number,
    total: Number,
    newPath: String,
    parentControllerId: String,
    userId: Number,
    container: String,
    deletable: String,
  };

  paths = {
    list: '/account/addresses/list',
  };

  connect() {
    // Dispatch address selection if there is an auto selected address.
    if (this.selectedAddressIdValue) {
      Dom.dispatchEvent('address:select', {
        address_id: this.selectedAddressIdValue,
      });
    }

    // Assign a controller ID to the controller's element when children
    // modals will be created outside it.
    if (this.hasExternalContainer) {
      this.controllerId = Date.now() + Math.random();
      this.controllerIdValue = this.controllerId;
    }

    if (this.componentsBillingAddressController?.checkboxTarget?.checked) {
      Dom.dispatchEvent('billing:has_address', {
        has_billing_address: 'true',
      });
    }

    document.addEventListener('billing:set_has_address', (event) => {
      if (event.detail.has_billing_address === 'false') {
        this.unselect();
        document.querySelector(`#has_billing_address`).checked = '';
      } else {
        document.querySelector(`#has_billing_address`).checked = event.detail.has_billing_address;
      }
    });
  }

  /**
   * Selector launcher.
   */
  launcher(event) {
    if (this.totalValue === 0) {
      this.open(event);
    } else {
      this.list(event);
    }
  }

  /**
   * List addresses.
   */
  list(event) {
    const target = event.currentTarget;

    const params = new URLSearchParams();
    params.set('country', this.mainController.countryValue);
    params.set('context', this.mainController.contextValue);
    params.set('entity', this.mainController.entityValue);
    params.set('input_name', this.mainController.inputNameValue);
    params.set('input_target', this.mainController.inputTargetValue);
    params.set('selected_address_id', this.mainController.selectedAddressIdValue);
    params.set('user_id', this.mainController.userIdValue);
    params.set('deletable', this.mainController.deletableValue);

    this.showSpinner(target);

    Request.get(`${this.paths.list}?${params.toString()}`, { contentType: 'text/html' })
      .then(({ response }) => {
        // Show list modal.
        Dom.insertHtml(this.mainController.element, response);
        this.updateTotal();
      })
      .finally(() => {
        this.modalClose(target);
      });
  }

  /**
   * Check if an external container was specified.
   * @return {boolean}
   */
  get hasExternalContainer() {
    return !!this.containerValue;
  }

  /**
   * Get container element.
   * @return {Element}
   */
  get container() {
    return this.hasExternalContainer
      ? Dom.element(this.containerValue)
      : this.element;
  }

  /**
   * Check if current controller has parent.
   * @return {boolean}
   */
  get hasParentController() {
    return !!this.data.parentControllerIdValue;
  }

  /**
   * Get main controller.
   * @return {Controller}
   */
  get mainController() {
    if (this.hasParentController) {
      return this.application.getControllerForElementAndIdentifier(
        document.querySelector(`[data-address-controller-id-value="${this.parentControllerIdValue}"]`),
        this.identifier,
      );
    }
    return this;
  }

  /**
   * Get billing address controller.
   * @return {Controller}
   */
  get componentsBillingAddressController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller$="components--billing-address"]'),
      'components--billing-address',
    );
  }

  /**
   * Create/edit address.
   */
  open(event) {
    const target = event.currentTarget;
    const path = target.getAttribute('data-path') || this.newPathValue;
    this.showSpinner(target);

    Request.get(path, { contentType: 'text/html' })
      .then(({ response }) => {
        // Show address form.
        this.createChildren(response);
      })
      .finally(() => {
        this.modalClose(target);
      });
  }

  /**
   * Save address single.
   */
  saveSingle(event) {
    const target = event.currentTarget;
    const form = this.element.querySelector('form');
    const dataObject = Dom.serializeObject(form);

    this.showSpinner(target);

    const url = form.getAttribute('action');
    const method = target.getAttribute('data-method') === 'put' ? 'PUT' : 'POST';

    Request.request(url, { method, body: dataObject, accept: 'application/json' })
      .then(({ response }) => {
        if (response.action === 'open') {
          // Open address's modal.
          this.createChildren(response.modal);
          return;
        }

        const addressLineOne = [response.address.address, response.address.postal_code].join(', ')
        const addressLineTwo = [response.address.province_name, response.address.city, response.address.phone].filter(Boolean).join(', ')
        let buttonTextElement = this.launcherTarget.querySelector('[data-button-text]');
        let actionElement = this.launcherTarget.querySelector('[data-action]');
        let buttonElement = this.launcherTarget.querySelector('button');

        if (buttonTextElement) {
          this.launcherTarget.setAttribute('data-completed', 'true');
          this.launcherTarget.querySelector('[data-address-line-1]').textContent = addressLineOne;
          this.launcherTarget.querySelector('[data-address-line-2]').textContent = addressLineTwo;
          buttonTextElement.textContent = 'Editar';
        } else if (actionElement) {
          actionElement.textContent = 'Cambiar';
        }

        if (buttonElement) {
          buttonElement.setAttribute('data-path', response.data_path);
        } else {
          // Show address list.
          Dom.insertHtml(this.mainController.element, response.view);
          this.mainController.updateTotal();
        }
      })
      .catch(({ error }) => {
        // Show address's modal with errors.
        this.createChildren(error);
      })
      .finally(() => {
        this.modalClose(target);
      });
  }

  /**
   * Delete address.
   */
  delete(event) {
    const target = event.currentTarget;

    this.showSpinner(target);

    Request.delete(target.getAttribute('data-path'), { contentType: 'text/html' })
      .then(({ response }) => {
        // Reset selected address.
        let deletedOptionValue = parseInt(this.optionValue(target));
        if (this.mainController.selectedAddressIdValue === deletedOptionValue ) {
          this.mainController.unselect();
        }
        // Show address list.
        Dom.insertHtml(this.mainController.element, response);
        this.mainController.updateTotal();
      })
      .finally(() => {
        this.modalClose(target);
      });
  }

  /**
   * Check if the event has been triggered inside a modal and
   * show the loading spinner accordingly.
   * @param {Element} target
   */
  showSpinner(target) {
    const modalController = this.modalController(target);
    if (modalController) {
      Spinner.show(modalController.dialogTarget);
    } else {
      target.setAttribute('disabled', '');
      Spinner.show(target);
    }
  }

  /**
   * Create children modal.
   * @param {string} content
   */
  createChildren(content) {
    if (!this.mainController.hasExternalContainer) {
      Dom.insertHtml(this.mainController.element, content);
    } else {
      Dom.insertHtml(this.mainController.container, `
        <div data-controller="address" data-address-parent-controller-id-value="${this.mainController.controllerId}">
          ${content}
        </div>
      `);
    }
  }

  /**
   * Update address total.
   */
  updateTotal() {
    setTimeout(() => {
      const total = this.hasAddressTarget
        ? this.addressTargets.length
        : 0;

      this.totalValue = total;
    }, 200);
  }

  /**
   * Get target's parent modal.
   * @param {Element} target
   * @return {Element}
   */
  modalElement(target) {
    return target.closest('[data-controller$="modal"]');
  }

  /**
   * Get target's parent modal controller.
   * @param {Element} target
   * @return {Controller}
   */
  modalController(target) {
    return this.application.getControllerForElementAndIdentifier(
      this.modalElement(target),
      'components--modal',
    );
  }

  /**
   * Modal close handler.
   * @param {Element} target
   * @return {boolean}
   */
  modalClose(target) {
    // Remove external children modal wrapper on modal close.
    if (this.hasParentController) {
      document.addEventListener('modal:closed', () => {
        this.element.remove();
      });
    }

    const closeOn = target.getAttribute('data-modal-close-on');
    if (closeOn) {
      Dom.dispatchEvent(`modal:close:${closeOn}`, {}, this.modalElement(target));
    } else {
      Spinner.remove();
      target.removeAttribute('disabled');
    }
  }

  /**
   * Set selected address.
   */
  select() {
    // Get selected address option from list.
    const option = this.element
      .querySelector('input[type="radio"][name="address"]:checked')
      ?.closest('[data-components--single-option-list-target*="option"]');
    if (option) {
      const selectedAddress = option.querySelector('input').value;
      this.selectedAddressIdValue = selectedAddress;
      this.setInputTargetValue(selectedAddress);

      // Dispatch address selection.
      Dom.dispatchEvent('address:select', {
        address_id: selectedAddress,
      });

      this.launcherTarget.querySelector('[data-address-line-1]').textContent = option.querySelector('[data-address-line-1]').textContent;
      this.launcherTarget.querySelector('[data-address-line-2]').textContent = option.querySelector('[data-address-line-2]').textContent;
      this.launcherTarget.setAttribute('data-completed', 'true');

      if (option.dataset.billing === 'true') {
        Dom.dispatchEvent('billing:has_address', {
          has_billing_address: 'true',
        });
      }
    }
  }

  /**
   * Reset selected address.
   */
  unselect() {
    this.selectedAddressId = null;
    this.launcherTarget.setAttribute('data-completed', 'false');
    this.setInputTargetValue('');

    // Dispatch unselect event.
    Dom.dispatchEvent('address:unselect');
  }

  /**
   * Set input target value.
   * @param {string|number} addressId
   */
  setInputTargetValue(addressId) {
    const inputTarget = document.querySelector(this.inputTargetValue || null);

    if (inputTarget) {
      inputTarget.value = addressId;
    }
  }

  /**
   * Get target's parent option element.
   * @param {Element} target
   * @return {Element}
   */
  option(target) {
    return target.closest('[data-components--single-option-list-target*="option"]');
  }

  /**
   * Get target's parent option value.
   * @param {Element} target
   * @return {string}
   */
  optionValue(target) {
    return this.option(target)?.querySelector('input').value;
  }
}
