// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'flowbite';
import "@fortawesome/fontawesome-free/js/all"

Rails.start()
ActiveStorage.start()

import "controllers"


// Load images to use them in Rails with image_pack_tag
require.context('../images', true)

require("jquery")
require("custom/export_by_channel")
require("custom/get_item_sp_api_by_channel")
