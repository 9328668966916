export default {
  listeners: [],

  /**
   * Get element.
   * @param {Element|string} element Element or selector.
   * @return {Element|null}
   */
  element(element) {
    if (element?.nodeType === Node.ELEMENT_NODE) {
      return element;
    }

    if (typeof element === 'string') {
      return document.querySelector(element);
    }

    return null;
  },

  /**
   * Check if specified element is an anchor.
   * @param {Element|string} element Element or selector.
   * @return {boolean}
   */
  isAnchor(element) {
    return this.element(element)?.nodeName === 'A';
  },

  /**
   * Insert HTML inside or around the specified element.
   * @param {Element|string} element Element or selector.
   * @param {string} html HTML content.
   * @param {string} [position='beforeend'] Position relative to the element.
   *   'beforebegin': Before the element itself.
   *   'afterbegin': Just inside the element, before its first child.
   *   'beforeend': Just inside the element, after its last child.
   *   'afterend': After the element itself.
   */
  insertHtml(element, html, position = 'beforeend') {
    this.element(element)?.insertAdjacentHTML(position, html);
  },

  /**
   * Add and store event listener.
   * @param {string} type Event type. Can be prefixed with a namespace and a colon as separator.
   * @param {function|EventListener} listener Event handler.
   * @param {boolean|object} [options=false] Listener options.
   * @param {EventTarget} [target=document] Target element.
   */
  addEventListener(type, listener, options = false, target = document) {
    const [namespace, eventType] = type.split(/:(.+)/);
    target.addEventListener(eventType, listener, options);
    this.listeners.push({
      namespace,
      target,
      type: eventType,
      listener,
      options,
    });
  },

  /**
   * Remove event listener.
   * @param {string} namespace Event namespace.
   */
  removeEventListener(namespace) {
    this.listeners = this.listeners.reduce((listeners, listener) => {
      if (listener.namespace === namespace) {
        listener.target.removeEventListener(listener.type, listener.listener, listener.options);
      } else {
        listeners.push(listener);
      }
      return listeners;
    }, []);
  },

  /**
   * Dispatch custom envent.
   * @param {string} event Event name.
   * @param {object} [data = {}] Event detail data.
   * @param {EventTarget} [target=document] Event target.
   */
  dispatchEvent(event, data = {}, target = document) {
    target.dispatchEvent(new CustomEvent(event, {
      detail: data,
    }));
  },

  /**
   * Trigger event.
   * Source: https://stackoverflow.com/a/49071358
   * @param target can be any DOM Element or other EventTarget
   * @param type Event type (i.e. 'click')
   */
  triggerEvent(target, type) {
    let event;

    if (typeof (Event) === 'function') {
      event = new Event(type);
    } else {
      event = document.createEvent('Event');
      event.initEvent(type, false, false);
    }

    target.dispatchEvent(event);
  },

  /**
   * Serialize all form data into an object of key/value pairs.
   * Source: https://vanillajstoolkit.com/helpers/serializeobject/
   * @param {Element|string} element Element or selector.
   * @return {Object} Serialized form data
   */
  serializeObject(element) {
    const obj = {};
    this.element(element)?.querySelectorAll('input, select, textarea').forEach((field) => {
      // Skip unwanted input fields.
      if (!field.name || field.disabled || ['file', 'reset', 'submit', 'button'].indexOf(field.type) > -1) return;
      // Skip not checked inputs.
      if (['checkbox', 'radio'].indexOf(field.type) > -1 && !field.checked) return;
      // Capture multi select input.
      if (field.type === 'select-multiple') {
        const options = [];
        Array.prototype.slice.call(field.options).forEach((option) => {
          if (!option.selected) return;
          options.push(option.value);
        });
        if (options.length) {
          obj[field.name] = options;
        } else {
          obj[field.name] = '';
        }
        return;
      }
      // Default capture input.
      obj[field.name] = field.value;
    });

    return this.nestObjects(obj);
  },

  /**
   * Nest objects.
   * Source: https://stackoverflow.com/a/30820483
   * @param {Object} obj
   * @return {Object}
   */
  nestObjects(obj) {
    const newObj = {}; let a; let j; let k; let o; let p; let q;

    Object.entries(obj).forEach(([key, value]) => {
      a = key.match(/([^[\]]+)(\[[^[\]]+[^\]])*?/g);
      p = value;
      j = a.length;
      while (j) {
        j -= 1;
        q = {};
        q[a[j]] = p;
        p = q;
      }
      // merge object
      [k] = Object.keys(p);
      o = newObj;
      while (k in o) {
        p = p[k];
        o = o[k];
        [k] = Object.keys(p);
      }
      o[k] = p[k];
    });

    return newObj;
  },

  /**
   * Current url add new param get.
   * @param {Object} obj
   * @return {String} current url with new param
   */
  addNewParamCurrentUrl(obj) {
    const currentUrl = window.location.href
    const urlSearchParams = new URLSearchParams(window.location.search)
    let params = Object.fromEntries(urlSearchParams.entries())

    // add new paramsKey to params
    params = Object.assign({}, params, obj)

    Object.keys(params).forEach(key => {
      if (params[key] === '' || params[key] === null || (Array.isArray(params[key]) && !params[key].length) || key === 'page') {
        delete params[key];
      }
    });

    // param url
    let url_params = new URLSearchParams(params).toString()

    url_params = url_params !== '' ? `?${url_params}` : ''

    return `${currentUrl.split('?')[0]}${url_params}`;
  },

  /**
   * Take keys from object.
   * @param {Object} data
   * @param {Array} keyArray
   * @return {Object} new object
   */
  takeKeysFromObject(data, keyArray) {
    return keyArray.reduce((obj, key) => {
      if (data.hasOwnProperty(key)) {
        return { ...obj, [key]: data[key] };
      }
      return obj;
    }, {});
  },

  camelCaseIdentifierController(str) {
    return str
      .split('--')
      .slice(-1)[0]
      .split(/[-_]/)
      .map(w => w.replace(/./, m => m.toUpperCase()))
      .join('')
      .replace(/^\w/, c => c.toLowerCase())
  }
};
