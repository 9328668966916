import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['workDay', 'clockIn', 'clockOut']

  connect() {
    this.update();
  }

  update() {
    const workDayValue = this.workDayTarget.value;
    const clockIn = this.clockInTarget;
    const clockOut = this.clockOutTarget;

    if (workDayValue && workDayValue !== '') {
      clockIn.min = clockOut.min = `${workDayValue}T00:00:00`;
      clockIn.max = clockOut.max = `${workDayValue}T23:59:59`;
    } else {
      clockIn.min = clockOut.min = '';
      clockIn.max = clockOut.max = '';
    }
  }
}
