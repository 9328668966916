import Dom from 'helpers/dom';

function setRelative(container) {
  const element = Dom.element(container);
  if (!element) return;

  if (element.style.position) {
    element.dataset.spinnerPreviousPosition = element.style.position;
  }
  element.style.position = 'relative';
}

function removeRelative(container) {
  const element = Dom.element(container);
  if (!element) return;

  element.style.position = element.dataset.spinnerPreviousPosition || '';
  delete element.dataset.spinnerPreviousPosition;
}

function removeSpinnerWrapper(container) {
  Dom.element(container)?.querySelector('.spinner-wrapper')?.remove();
}

export default {
  /**
   * Show loading spinner on specified container.
   * @param {string|Element} [container=body] Container.
   * @param {boolean} [relative=true] Position element as relative.
   */
  show(container = 'body', relative = true) {
    removeSpinnerWrapper(container);
    if (relative) {
      setRelative(container);
    }
    Dom.insertHtml(
      container,
      '<div class="spinner-wrapper"><div class="spinner"></div></div>',
    );
  },

  /**
   * Remove loading spinner from specified container.
   * @param {string|Element} [container] Container.
   */
  remove(container = 'body') {
    removeSpinnerWrapper(container);
    removeRelative(container);
  },
};
