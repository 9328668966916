import consumer from "./consumer"

export function subscribeDownloadChannel(uuid, callback) {
  consumer.download = consumer.subscriptions.create(
    { channel: "DownloadChannel", uuid: uuid },
    {
      connected: function() {
        callback();
      },

      disconnected: function() {},

      received: function(data) {

        if( data.export_type == 'pdf' ){

          window.location="/admin/get_tmp_file/" + data.content

        } else {

          var file = new Blob([data.content], {encoding:"UTF-8", type: 'text/csv;charset=utf-8'});
          var fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
          // replace download to link for can add filename
          var download_file = document.createElement("a");
          download_file.href = fileURL;
          download_file.download = data.file_name;
          download_file.click();


        }

        $("a[data-uuid='" + uuid +"']")
          .html("Descargado")
          .removeAttr("disabled")
          .removeAttr('data-uuid');

        consumer.download.unsubscribe();
        consumer.disconnect();
        delete consumer.download;
      }
    }
  );
}
