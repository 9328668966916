import { Controller } from "@hotwired/stimulus"
import Dom from 'helpers/dom';

export default class extends Controller {

  connect() {
    let paymentForm = Dom.element('#redsys-payment-form');

    if (paymentForm !== null) {
      setTimeout(() => {
        paymentForm.submit();
      }, 3000);
    }
  }
}
