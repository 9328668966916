import { Controller } from "@hotwired/stimulus"
import _debounce from "lodash/debounce"
import Dom from 'helpers/dom';
import Request from 'helpers/request';
import Spinner from 'helpers/spinner';

// Connects to data-controller="filters"
export default class extends Controller {
  static targets = ['condition', 'binding', 'languageId', 'categoryId', 'buttonRemove'];
  static searchParamKeys = ["author_ids", "publisher_ids", "condition", "binding", "language_id", "category_id"]

  initialize() {}

  connect() {
    // Listen to the change event on the input autocomplete multiple controller to redirect to the new url
    Dom.addEventListener(`${this.identifier}:redirect:selected`, this.redirectCurrentUrl.bind(this));
    this.loadButtonRemove();
  }

  // Adds the selected filter_multiple_controller to current url params
  redirectCurrentUrl(event) {
    event.preventDefault()

    const updatedUrl = Dom.addNewParamCurrentUrl(event.detail)

    // redirect to new url
    Turbo.visit(updatedUrl, { frame: 'items_table'})
  }

  addParamsSelected(event) {
    event.preventDefault()
    const objNamesSelected = event.detail

    Object.keys(objNamesSelected).forEach(key => {
      const parentElementFilter = this.element.querySelector(`#${key}_filter_selected_modal`);
      parentElementFilter.innerHTML = objNamesSelected[key];
    })
  }

  showResults(event) {
    event.preventDefault();

    const paramsFilter = this.getParamsFilter();
    const updatedUrl = Dom.addNewParamCurrentUrl(paramsFilter)

    // redirect to url with params
    Turbo.visit(updatedUrl, { frame: 'items_table'})
  }

  selectFilter(event) {
    event.preventDefault();

    const paramKey = event.currentTarget.dataset.key;

    const paramValue = event.currentTarget.dataset.value;

    const parentElementFilter = Dom.element(`#${paramKey}_filter`);

    this.updateFilters(parentElementFilter, paramKey, paramValue);
  }

  updateSubtitleFilter(paramKey) {
    const selectedElementFilter = this.element.querySelector(`#${paramKey}_filter_selected_modal`);

    const selectedValue = this.element.querySelector(`#${paramKey}_selected`);

    if(selectedValue) {
      selectedElementFilter.innerText = selectedValue.innerText;
    } else {
      selectedElementFilter.innerText = "";
    }
  }

  removeFilter(event) {
    event.preventDefault();

    this.constructor.searchParamKeys.forEach((key) => {
      const parentElementFilter = this.element.querySelector(`#${key}_filter`);

      if (key === "author_ids" || key === "publisher_ids") {
        $(document).trigger('filter_multiple_controller.state', function(filterMultipleController) {
          filterMultipleController.rowIds = [];

          filterMultipleController.inputHideRowIdsTarget.innerHTML = "";

          filterMultipleController.selectedRowsBoxTarget.innerHTML = "";

        });

        const subtitle = Dom.element(`#${key}_filter_selected_modal`);

        if (typeof(subtitle) !== 'undefined' && subtitle !== null) {
          this.element.querySelector(`#${key}_filter_selected_modal`).innerText = "";
        }
      } else {
        this.updateFilters(parentElementFilter, key, '');
      }
    });

    this.disableButtonRemove();
  }

  disableButtonRemove() {
    this.buttonRemoveTarget.setAttribute('disabled', '');
    this.buttonRemoveTarget.classList.add('disabled-light-grey');
  }

  enableButtonRemove() {
    this.buttonRemoveTarget.removeAttribute('disabled');
    this.buttonRemoveTarget.classList.remove('disabled-light-grey');
  }

  loadButtonRemove() {
    if (this.hasButtonRemoveTarget) {
      let paramsFilter = this.getParamsFilter();
      if (Object.keys(paramsFilter).every(key => paramsFilter[key] === '')) {
        this.disableButtonRemove();
      } else {
        this.enableButtonRemove();
      }
    }
  }

  getParamsFilter() {
    const data = Dom.serializeObject(this.element);
    return Dom.takeKeysFromObject(data, this.constructor.searchParamKeys);
  }

  updateFilters(element, paramKey, paramValue) {
    this.updateTarget(paramKey, paramValue);
    this.loadFilterSelect(element, paramKey, paramValue);
    this.loadButtonRemove();
  }

  loadFilterSelect(elementToReplace, key, value) {
    Spinner.show(elementToReplace);
    let url = elementToReplace.dataset["url"];
    const params = new URLSearchParams();

    if(typeof value !== "undefined" && value !== '' && value !== null) {
      params.set(key, value);

      url = `${elementToReplace.dataset["url"]}?${params.toString()}`;
    }

    Request.get(`${url}`, { contentType: 'text/html' })
      .then(({ response }) => {
        elementToReplace.innerHTML = response;

        this.updateSubtitleFilter(key);
      })
      .finally(() => {
        Spinner.remove();
      });
  }

  updateTarget(key, value) {
    if (this.targets.findTarget(Dom.camelCaseIdentifierController(key))) {
      this.targets.findTarget(Dom.camelCaseIdentifierController(key)).value = value;
    }
  }
}
