import consumer from "./consumer"
import Spinner from 'helpers/spinner';
export function subscribeGetCatalogItemChannel(uuid, callback, element) {
  consumer.item = consumer.subscriptions.create(
    {channel: "GetCatalogItemChannel", uuid: uuid},
    {
      connected: function() {
        callback();
      },
      disconnected: function() {},
      received: function(data) {
        console.log(data)
        if(data.response !== null) {
          element.item_name.value = data.response.title
          element.item_sell_price_admin.value = data.response.sell_price_admin
          element.item_retail_price.value = data.response.pvp_price
          element.item_binding.value = data.response.binding
          element.item_condition.value = data.response.sub_condition
        } else {
          if (data.result === 'error'){
            showAlert(data.message_error, 'error', true);
          } else {
            showAlert('Libro no encontrado', 'alert', true);
          }

        }
        $("a[data-uuid='" + uuid +"']")
          .html("Buscar en amazon")
          .removeAttr("disabled")
          .removeAttr('data-uuid');
        Spinner.remove();

        consumer.item.unsubscribe();
        consumer.disconnect();
        delete consumer.item;
      }
    }
  );
}
