import { Controller } from "@hotwired/stimulus"
import consumer from '../../channels/consumer';

export default class extends Controller {
  connect() {
    this.channelAlive = false;
    this.channel = consumer.subscriptions.create('WorkTimesChannel', {
      connected: this.channelConnected.bind(this),
      disconnected: this.channelDisconnected.bind(this),
      received: this.replace.bind(this),
    });
  }

  disconnect() {
    this.channel.unsubscribe();
    this.channelAlive = false;
  }

  toggle() {
    if (!this.channelAlive) return;
    this.channel.perform('toggle_clock');
  }

  replace(data) {
    this.element.innerHTML = data.button;
  }

  channelConnected() {
    this.channelAlive = true;
  }

  channelDisconnected() {
    this.channelAlive = false;
  }
}
