import { Controller } from "@hotwired/stimulus"
import _debounce from "lodash/debounce"
import Dom from 'helpers/dom';
import Request from 'helpers/request';
import Spinner from 'helpers/spinner';

export default class extends Controller {
  static targets = ["identifier", "title", "isbn", "image_url",
                    "sourceImg", "search_url", "search_message", "container_result", "suggestion",
                    "pages", "publication_year", "item_language", "asin_number", "categories", "publisherId",
                    "publisherName", "inputHideAuthorIds", "selectedAuthorsBox", "link"];
  static _search_param
  static values = {
    url: String,
    initialAuthorIds: Array
  };

  initialize(){
    this.suggest = _debounce(this.suggest, 1000).bind(this)
  }

  connect() {
    this.authorIds = this.initialAuthorIdsValue;

    document.getElementById('authors-autocomplete').addEventListener("autocomplete.change", this.authorSelectEvent.bind(this))
  }

  // Adds the selected author badge
  authorSelectEvent(event) {
    if (this.authorIds.includes(event.detail.value)) { return; }

    this.authorIds.push(event.detail.value);

    // Create hidden select options with selected author ids
    this.loadSelectedOptions(this.authorIds);

    this.loadAuthorBadges(this.authorIds);
  }

  suggest(event) {
    this._search_param = {}
    this._search_param[event.target.id] = event.target.value

    if (event.target.value === '' || typeof (event.target.value) === 'undefined') {
      this.suggestionTarget.hidden = true
      event.preventDefault();
      return
    }
    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')?.content,
      },
      body: new URLSearchParams(this._search_param)
    }).then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html));
  }

  search(event) {
    this.search_messageTarget.hidden = false
    fetch(this.search_urlTarget.value, {
      method: 'GET',
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')?.content,
      }
    }).then(r => r.text())
      .then(html => {
        this.search_messageTarget.hidden = true
        Turbo.renderStreamMessage(html)
      });
  }

  setData({params}){
    let item = params.item
    let inputsEl = {
      item_name: Dom.element('#item_name'),
      item_sell_price_admin: Dom.element('#item_sell_price_admin'),
      item_retail_price: Dom.element('#item_retail_price'),
      item_binding: Dom.element('#item_binding'),
      item_condition: Dom.element('#item_condition'),
      item_image_url: Dom.element('#item_image_url'),
      item_categories: Dom.element('#item_category_ids'),
    }

    console.log(item);

    const author_ids = new Array();
    item.authors.forEach((element) => {
      author_ids.push(element[0]);
    });

    // Create hidden select options with selected author ids
    this.loadSelectedOptions(author_ids);

    this.loadAuthorBadges(author_ids);

    if (typeof(item) !== "undefined") {
      this.suggestionTarget.hidden = true
      inputsEl.item_name.value = item.title
      inputsEl.item_sell_price_admin.value = item.sell_price_admin
      inputsEl.item_retail_price.value = item.pvp_price
      inputsEl.item_binding.value = item.binding
      inputsEl.item_condition.value = item.sub_condition
      this.identifierTarget.value = item.ean_identifier
      this.isbnTarget.value = item.isbn_identifier
      this.image_urlTarget.value = item.url_image
      this.sourceImgTarget.src = item.url_image
      this.sourceImgTarget.alt = item.title
      this.pagesTarget.value = item.pages
      this.publication_yearTarget.value = item.publication_year
      this.item_languageTarget.value = item.item_language
      this.asin_numberTarget.value = item.asin_number
      //TODO Set values using Vanilla JS instead of JQuery
      $('#item_category_ids').val(item.categories)
      this.publisherIdTarget.value = item.publisher[0]
      this.publisherNameTarget.value = item.publisher[1]
    }
  }

  removeAuthor(event) {
    let authorId = event.currentTarget.id;

    this.authorIds = this.authorIds.filter(function(item) {
      return item != authorId
    })

    this.inputHideAuthorIdsTarget.querySelector('option[value="' + authorId + '"]').remove();
    event.currentTarget.remove()
  }

  resetSuggest(){
    this.suggestionTarget.hidden = true
  }

  loadAuthorBadges(author_ids){
    Spinner.show(this.element);

    const params = new URLSearchParams();
    params.set('author_ids', author_ids);

    Request.get(`${this.linkTarget.dataset["url"]}?${params.toString()}`, { contentType: 'text/html' })
      .then(({ response }) => {
        document
          .querySelector('[data-items--suggestion-target="selectedAuthorsBox"]')
          .innerHTML = response;
      })
      .finally(() => {
        Spinner.remove();
      });
  }

  loadSelectedOptions(author_ids){
    this.inputHideAuthorIdsTarget.innerHTML = "";
    for (let index = 0; index < author_ids.length; index++) {
      this.inputHideAuthorIdsTarget.add(new Option(author_ids[index], author_ids[index], true, true));
    }
  }
}
