import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["icon", "details", "subtitle"];
  static classes = ["rotate"];
  static values = {
    expanded: Boolean,
  }

  initialize() {
    if (this.expandedValue) {
      this.expand();
    }
  }

  toggle() {
    this.iconTarget.classList.toggle("fa-angle-down");
    this.iconTarget.classList.toggle("fa-angle-up");

    if (this.hasSubtitleTarget) {
      this.subtitleTarget.classList.toggle('hidden');
    }

    if (this.detailsTarget.style.maxHeight) {
      this.detailsTarget.style.maxHeight = null;
    } else {
      this.detailsTarget.style.maxHeight = 'max-content';
    }
  }

  expand() {
    this.iconTarget.classList.add("fa-angle-up");
    this.iconTarget.classList.remove("fa-angle-down");
    this.detailsTarget.style.maxHeight = 'max-content' ;
  }
}

