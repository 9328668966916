import { Controller } from "@hotwired/stimulus";
import Spinner from 'helpers/spinner';

export default class extends Controller {
  connect() {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = entry.target;

          Spinner.show(img.parentElement);

          img.src = img.dataset.src;
          img.onload = () => {
            Spinner.remove(img.parentElement);
          };

          observer.unobserve(img);
        }
      });
    });

    observer.observe(this.element);
  }
}
