import { subscribeDownloadChannel } from "../channels/download_channel";

window.generateUUID = function () {
    var S4 = function() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      S4() +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      S4() +
      S4()
    );
}
// block start
window.LabelsLink = function(){
  let _element, _uuid, _url;
  let _generate = (e) => {
    e.preventDefault();
    _uuid = generateUUID();
    _element = $(e.target);
    _element.html('Descargando...').attr('disabled', 'disabled');
    _element.attr('data-uuid', _uuid);
     _url = _element.attr('href') + '?uuid=' + _uuid + '&val1=' + $('.val1').val() + '&val2=' + $('.val2').val() + '&val3=' + $('.val3').val();;

    subscribeDownloadChannel(_uuid, function() {
      $.get(_url);
    });
    return false;
  }
  return {
    generate: (e) => _generate(e),
  }
}();
// block end

/**
 * Show alert message.
 * @param {string} message Alert message.
 * @param {string} type Alert type.
 * @param {boolean} [removePrevious] Remove previous alerts.
 */
window.showAlert = function(message, type, removePrevious) {
  if (removePrevious !== false) {
    $('[data-controller="alert"]').remove();
  }
  var bg_color = {
    'notice': 'bg-teal-500 border-teal-700',
    'alert': 'bg-yellow-400 border-yellow-600',
    'error': 'bg-red-600 border-red-600'
  }
  var html = `<div class="fixed inset-x-0 top-0 flex items-end justify-right px-4 py-3 sm:py-3 sm:px-6 justify-end z-30 pointer-events-none">
                <div data-controller="alert" class="max-w-sm w-full shadow-lg rounded px-4 py-3 relative ${bg_color[type]}> border-l-4 text-white pointer-events-auto">
                  <div class="p-2">
                    <div class="flex items-start">
                      <div class="ml-3 w-0 flex-1 pt-0.5">
                        <p class="text-sm leading-5 font-medium">
                          ${message}
                        </p>
                      </div>
                      <div class="ml-4 flex-shrink-0 flex">
                        <button data-action="alert#close" class="inline-flex text-white focus:outline-none focus:text-gray-300 transition ease-in-out duration-150">
                          <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"/>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

  `;
  $('main').prepend(html);
}

