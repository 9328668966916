import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['trigger', 'dropdownToggle', 'dropdownToggleTrigger'];
  static dropdown;
  static visible;

  connect() {
    // set the target element that will be collapsed or expanded (eg. navbar menu)
    this.visible = false
  }

  /**
   * Toggle dropdown.
   */
  toggle() {
    const targetEl = this.dropdownToggleTarget;
    const triggerEl = this.dropdownToggleTriggerTarget;
    const opts = {
      placement: 'bottom',
      onHide: () => {
        this.visible = false
      },
      onShow: () => {
        this.visible = true
      }
    }
    this.dropdown = new Dropdown(targetEl, triggerEl, opts);
    if (!this.visible) {
      this.dropdown.show();
    } else {
      this.dropdown.hide();
    }

  }

}
