import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (window.location.hash) {
      this.openQuestion(undefined, window.location.hash.split('#').pop());
    }
  }

  /**
   * Open question.
   * @param {string} id Question ID attribute.
   */
  openQuestion(event, id) {
    const questionId = id || event.params.id;
    const question = document.querySelector(`[id=${questionId}]`);
    if (question) {
      window.setTimeout(() => {
        this.dispatch('open', { target: question, prefix: false });
        question.scrollIntoView();
      }, 100);
    }
  }
}
