import { Controller } from '@hotwired/stimulus';
import Dom from 'helpers/dom';
import Spinner from 'helpers/spinner';
import Request from 'helpers/request';

export default class extends Controller {
  static values = { path: String }

  open(event) {
    Spinner.show(event.currentTarget);
    Request.get(this.pathValue, { contentType: 'text/html' })
      .then(({ response }) => {
        Dom.insertHtml('body', response);
      })
      .finally(() => {
        Spinner.remove();
      });
  }
}
