import { Controller } from "@hotwired/stimulus"
import _debounce from "lodash/debounce"
import Dom from 'helpers/dom';
import Request from 'helpers/request';
import Spinner from 'helpers/spinner';

export default class extends Controller {
  static targets = ["inputHideRowIds", "selectedRowsBox", "link", "inputSimple", "rowsAutocomplete", "selectedName"]

  static _search_param
  static values = {
    url: String,
    initialRowIds: Array,
    paramKey: String,
    emmitEvent: String
  };

  connect() {
    $(document).on('filter_multiple_controller.state', function(event, callback) {
        callback(this)
      }.bind(this)
    )

    this.rowIds = this.initialRowIdsValue;

    this.rowsAutocompleteTarget.addEventListener("autocomplete.change", this.selectEvent.bind(this));

    this.loadSelectedOptions(this.rowIds);

    this.loadRowBadges(this.rowIds);
  }


  // Adds the selected row badge
  selectEvent(event) {
    if (this.rowIds.includes(event.detail.value)) { return; }

    this.rowIds.push(event.detail.value);

    // Create hidden select options with selected row ids
    this.loadSelectedOptions(this.rowIds);

    this.loadRowBadges(this.rowIds, true);
  }

  loadSelectedOptions(row_ids){
    this.inputHideRowIdsTarget.innerHTML = "";

    for (let index = 0; index < row_ids.length; index++) {
      this.inputHideRowIdsTarget.add(new Option(row_ids[index], row_ids[index], true, true));
    }
  }

  loadRowBadges(row_ids, emmitEvent = false){
    Spinner.show(this.element);

    const params = new URLSearchParams();
    params.set(this.paramKeyValue, row_ids);


    Request.get(`${this.linkTarget.dataset["url"]}?${params.toString()}`, { contentType: 'text/html' })
      .then(({ response }) => {
        this.inputSimpleTarget.value = '';
        this.selectedRowsBoxTarget.innerHTML = response;
        if (emmitEvent) { this.emmitEvent(); }
      })
      .finally(() => {
        Spinner.remove();
      });
  }

  remove(event) {
    let rowId = event.currentTarget.id;
    let name_selected = this.selectedNameTarget.innerText.split(', ');
    let name_to_remove = event.currentTarget.innerText.replace(/\nRemove badge/g, '');
    let name_select = name_selected.filter(function(item) {
      return item != name_to_remove
    }).join(', ')

    this.rowIds = this.rowIds.filter(function(item) {
      return item != rowId
    })

    this.inputHideRowIdsTarget.querySelector('option[value="' + rowId + '"]').remove();

    event.currentTarget.remove();

    this.selectedNameTarget.innerHTML = name_select;

    this.emmitEvent();
  }

  emmitEvent() {
    if ('redirect:selected' === this.emmitEventValue) {
      Dom.dispatchEvent(this.emmitEventValue, {
        [this.paramKeyValue]: this.rowIds
      });
    } else {
      this.dispatch('selected', {detail: {
          [this.paramKeyValue]: this.selectedNameTarget.innerText
        }});
    }
  }
}
