import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.secondsLeft = this.secondsLeft || parseFloat(this.element.getAttribute('data-seconds'));
    clearInterval(this.timeInterval);
    this.timeInterval = setInterval(this.update.bind(this), 1000);
    this.update();
  }

  update() {
    this.secondsLeft -= 1;
    const date = new Date((new Date()).setMinutes(0, this.secondsLeft, 0));
    const minutes = `0${date.getMinutes()}`.slice(-2);
    const seconds = `0${date.getSeconds()}`.slice(-2);
    this.element.innerHTML = `${minutes}:${seconds}`;

    if (this.secondsLeft <= 0) {
      clearInterval(this.timeInterval);
      window.location.reload();
    }
  }
}
