import { Controller } from "@hotwired/stimulus"
import Dom from 'helpers/dom';
import Spinner from 'helpers/spinner';
import Request from 'helpers/request';


export default class extends Controller {
  static targets = ['itemsContainer']

  events = {
    open: 'cart:open',
    close: 'cart:close',
    add: 'cart:add',
    delete: 'cart:delete',
    restore: 'cart:restore',
  };

  paths = {
    items: '/cart_items',
    restore: '/restore_cart',
    get: '/get_cart',
    touch: '/cart/touch',
  };

  connect() {
    this.setItemState();

    // Close cart on outside click.
    Dom.addEventListener(`${this.identifier}:click`, (event) => {
      if (
        this.isOpen
        && !event.target.closest('[data-action$="cartToggle"]')
        && !event.target.closest(`[data-controller="${this.identifier}"]`)
        && !event.target.closest('.modal')
      ) {
        this.close();
      }
    }, true);

    // Reload cart if the page was loaded from cache or accessed by navigating
    // into the history.
    Dom.addEventListener(`${this.identifier}:pageshow`, (event) => {
      if (event.persisted || window.performance?.navigation.type === 2) {
        this.reload();
      }
    }, false, window);
  }

  disconnect() {
    Dom.removeEventListener(this.identifier);
  }

  /**
   * Get header controller.
   * @return {Controller}
   */
  get headerController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller="header"]'),
      'header',
    );
  }

  /**
   * Get cart toggle element.
   * @return {Element}
   */
  get toggleTarget() {
    return this.headerController.cartToggleTarget;
  }

  /**
   * Check if cart layer is open.
   * @return {boolean}
   */
  get isOpen() {
    return this.element.hasAttribute('data-open');
  }

  /**
   * Get cart items count.
   * @return {number}
   */
  get totalItems() {
    if (!this.hasItemsContainerTarget) return 0;

    return this.itemsContainerTarget.querySelectorAll('[data-cart-item]').length;
  }

  /**
   * Check if cart has items.
   * @return {boolean}
   */
  get hasItems() {
    return this.element.getAttribute('data-has-items') === 'true';
  }

  /**
   * Toggle cart.
   */
  toggle(event) {
    if (
      this.element.getAttribute('data-present') === 'true'
      && this.element.getAttribute('data-interactive') === 'true'
      && this.element.getAttribute('data-has-items') === 'true'
    ) {
      this.isOpen ? this.close() : this.open();
    } else {
      // Run empty cart animation.
      const cartToggleBtn = event.currentTarget;
      if (!cartToggleBtn.classList.contains('shake')) {
        cartToggleBtn.classList.add('shake');
        setTimeout(() => {
          cartToggleBtn.classList.remove('shake');
        }, 850);
      }
    }
  }

  /**
   * Open cart layer.
   */
  open() {
    if (this.isOpen) return;

    // Set cart's layer top position.
    this.setTop();

    if (this.headerController.isSmallScreen) {
      // Store scroll position to restore it when cart closes.
      this.scrollPosition = document.documentElement.scrollTop;
      document.body.style.top = `${this.scrollPosition * -1}px`;
      // Disable scroll.
      document.body.classList.add('overlay-open');
    } else {
      // // Set toggle's left position to prevent displace when modals open.
      // if (document.body.hasAttribute('data-header-cart-fixed')) {
      //   this.toggleTarget.style.left = `${this.toggleTarget.getBoundingClientRect().left}px`;
      // }

      // Set cart's layer left position.
      const left = this.toggleTarget.getBoundingClientRect().left
        + this.toggleTarget.getBoundingClientRect().width
        - this.element.offsetWidth
        + 12; // (toggle side padding + ~border)
      this.element.style.left = `${left}px`;
    }

    // Flag cart as open.
    this.element.setAttribute('data-open', '');
    this.toggleTarget.setAttribute('data-open', '');

    // Update cart's layer height.
    this.setHeight();

    Dom.dispatchEvent(this.events.open);
  }

  /**
   * Close cart layer.
   */
  close() {
    if (!this.isOpen) return;

    // Flag cart as closed.
    this.toggleTarget.removeAttribute('data-open');
    this.element.removeAttribute('data-open');

    // Enable scroll.
    document.body.classList.remove('overlay-open');

    // Reset cart position.
    // this.toggleTarget.style.left = null;
    this.element.style.left = null;

    // Restore scroll position.
    if (this.headerController.isSmallScreen) {
      document.body.style.top = '0px';
      window.scrollTo(0, this.scrollPosition);
    }

    Dom.dispatchEvent(this.events.close);
  }

  /**
   * Calc layer's top position.
   */
  setTop() {
    let top = 0;
    if (this.headerController.isSmallScreen) {
      top = this.headerController.height;
    } else {
      top = document.documentElement.scrollTop === 0 ? 0 : this.toggleTarget.offsetHeight;
      if (!document.body.hasAttribute('data-header-cart-fixed')) {
        top += this.headerController.element.children[0].offsetHeight
          - document.documentElement.scrollTop;
      }
    }
    this.element.style.top = `${top}px`;
  }

  /**
   * Calc scrollable contents height.
   */
  setHeight() {
    if (!this.hasItemsContainerTarget || !this.isOpen) return;

    let height = Array.from(this.element.children)
      .filter((children) => children !== this.itemsContainerTarget)
      .reduce((acumulator, children) => acumulator + children.offsetHeight, 0);
    height += parseFloat(window.getComputedStyle(this.element).paddingBottom) || 0;

    if (this.headerController.isSmallScreen) {
      this.itemsContainerTarget.style.height = `calc(100% - ${height}px)`;
    } else {
      this.itemsContainerTarget.style.maxHeight = `${height}px`;
    }
  }

  /**
   * Add item to cart.
   */
  add(event) {
    const target = event.currentTarget;
    const itemElement = target.closest('[data-controller="item-thumb"]') || target.closest('[data-controller="item"]');

    // Spinner will be attached to "add" button instead of item's container.
    if (itemElement.getAttribute('data-controller') === 'item') {
      Spinner.show(target);
    } else {
      Spinner.show(itemElement);
    }

    const data = { cart_item: { item_id: itemElement.getAttribute('data-id') } };
    Request.post(this.paths.items, { body: data })
      .then(({ response }) => {
        Dom.dispatchEvent(this.events.add, { itemElement });
        this.replace(response.top_cart);
      })
      .catch(({ error }) => {
        if (error?.id === 'not_available') {
          // Show notification/info modal.
          Dom.insertHtml('body', error.not_available);
        } else if (error?.id === 'cart_items_exceeded') {
          Dom.insertHtml('body', error.cart_items_exceeded);
        }
      })
      .finally(() => {
        Spinner.remove();
      });
  }

  /**
   * Delete item from cart.
   */
  delete(event) {
    Spinner.show(this.element, false);
    const cartItemElement = event.currentTarget.closest('[data-cart-item]');
    Request.delete(`${this.paths.items}/${cartItemElement.getAttribute('data-id')}`)
      .then(({ response }) => {
        Dom.dispatchEvent(this.events.delete, { itemElement: cartItemElement });
        this.replace(response.top_cart);
        // Close cart if there are no items.
        if (!this.hasItems) {
          this.close();
        }
      });
  }

  /**
   * Restore cart.
   */
  restore(event) {
    if (event.currentTarget.getAttribute('data-remove') === false) {
      Dom.dispatchEvent(this.events.restore);
    } else {
      Spinner.show(this.element, false);
      Request.post(this.paths.restore)
        .then(({ response }) => {
          Dom.dispatchEvent(this.events.restore);
          this.replace(response.top_cart);
        })
        .catch(({ error }) => {
          if (error) {
            this.element.children[0].innerHTML = `<p>${error.message}</p>`;
            setTimeout(() => {
              this.close();
              this.replace(error.top_cart);
            }, 5000);
          } else {
            Request.reload();
          }
        });
    }
  }

  /**
   * Reset cart timeout.
   */
  touch() {
    Request.post(this.paths.touch);
  }

  /**
   * Reload cart.
   */
  reload() {
    Request.get(this.paths.get)
      .then(({ response }) => {
        this.replace(response.top_cart);
      });
  }

  /**
   * Cart contents replacement.
   * @param {string} contents New cart contents.
   */
  replace(contents) {
    this.element.innerHTML = contents;
    this.setHeight();
    this.setItemState();

    // Update toggle's total.
    setTimeout(() => {
      this.toggleTarget.querySelector('[data-total-items]').textContent = this.totalItems;
    }, 100);

    // Animate toggle.
    const toggleTargetImg = this.toggleTarget.querySelector('img');
    if (!toggleTargetImg.classList.contains('flip')) {
      toggleTargetImg.classList.add('flip');
      setTimeout(() => {
        toggleTargetImg.classList.remove('flip');
      }, 250);
    }


    // Update "cart present" flag.
    this.element.setAttribute('data-present', this.hasItemsContainerTarget);

    // Update "has items" flag.
    this.element.setAttribute('data-has-items', this.totalItems > 0);
  }

  /**
   * Update items state, in case they are already in user's cart.
   */
  setItemState() {
    if (!this.hasItemsContainerTarget) return;

    // Reset items state.
    document.querySelectorAll(`
      [data-controller="item"][data-in-cart="true"],
      [data-controller="item-thumb"][data-in-cart="true"]
    `).forEach((itemElement) => {
      itemElement.setAttribute('data-in-cart', 'false');
    });

    // Iterate through all cart items and update thumbs state.
    this.itemsContainerTarget
      .querySelectorAll('[data-cart-item]')
      .forEach((cartItemElement) => {
        const itemId = cartItemElement.getAttribute('data-item-id');
        document.querySelector(`
          [data-controller="item"][data-id="${itemId}"],
          [data-controller="item-thumb"][data-id="${itemId}"]
        `)?.setAttribute('data-in-cart', 'true');
      });
  }
}
