import { Controller } from "@hotwired/stimulus"
import Dom from 'helpers/dom';
import Spinner from 'helpers/spinner';
import Request from 'helpers/request';

export default class extends Controller {
  static targets = [
    'subtotal', 'shippingAmount', 'total', 'totalEur', 'shipping', 'address', 'addressInput',
    'pickUpPoint', 'shippingDate', 'form', 'submit', 'detailsShippingMethodDesc', 'requiredFields',
    'coupon', 'couponInput', 'promotionOpt',
  ];

  static values = {
    shippingMode: String,
    shippingMethod: String,
    shippingCompany: String,
    addressId: Number,
    pickUpPointId: String,
    pickUpPointAddress: String,
    payment: String,
    paymentSave: String,
    shippingDateMode: String,
    shippingDateDate: String,
    promotionAvailable: Boolean,
    promotionApplicable: Boolean,
  }

  connect() {
    // Reset cart timeout. Ignore page reload and direct access
    // to prevent item's block abuse.
    if (
      window.PerformanceNavigationTiming
      && document.referrer
      && (document.location.href.split('/')[2] === document.referrer.split('/')[2])
      && window.performance.getEntriesByType('navigation')[0].type !== 'reload'
    ) {
      this.cartController?.touch();
    }

    // Shipping option change handler.
    Dom.addEventListener(`${this.identifier}:shipping:change`, (event) => {
      // Shipping method.
      this.shippingMethodValue = event.detail.value;

      // Extract company and mode.
      const [shippingCompany, shippingMode] = this.shippingMethodValue?.split('~');
      this.shippingCompanyValue = shippingCompany;
      this.shippingModeValue = shippingMode;

      // Dispatch change to pick up point selector.
      Dom.dispatchEvent('pick_up_point:set', {
        delivery_company: shippingCompany,
      });

      // Refresh shipping data.
      this.refreshShippingData();
      this.detailsShippingMethodDescTargets
        .forEach((target) => target.classList.add('hidden'));
      this.detailsShippingMethodDescTargets
        .find((target) => target.getAttribute('data-shipping-method') === event.detail.value)
        ?.classList.remove('hidden');

      this.checkSubmit();
    });

    // Address option change handler.
    Dom.addEventListener(`${this.identifier}:address:select`, (event) => {
      this.addressIdValue = event.detail.address_id;
      const addressIdValue = event.detail.address_id;

      Request.post(`/checkout/deliveries_info?address_id=${addressIdValue}`)
        .then(({ response }) => {
          response.shipping_options.forEach((option) => {
            if (option.has_excluded_zip_codes === true) {
              document.getElementById(option.element_id).checked = false;
              document.getElementById(option.element_id).disabled = true;
              document.getElementById(option.element_id).parentElement.dataset.disabled = true;
              const aux = document.querySelector(`label[for=${option.element_id}]`).outerHTML.replace('</label>', `<div data-disabled-with>${option.disable_with_text}</div></label>`);

              if (!document.querySelector(`label[for=${option.element_id}]`).outerHTML.includes(option.disable_with_text)) {
                document.querySelector(`label[for=${option.element_id}]`).outerHTML = aux;
              }
            } else if (document.getElementById(option.element_id) != null) {
              document.getElementById(option.element_id).disabled = false;
              document.getElementById(option.element_id).parentElement.dataset.disabled = false;
              const items = document.querySelectorAll('[data-disabled-with]');
              items.forEach((item) => {
                if (item.innerText === option.disable_with_text
                    && item.parentElement.htmlFor === option.element_id) item.remove();
              });
            }
          });

          const radioButtons = document.querySelectorAll('[name="shipping"]');
          let allUnchecked = true;

          radioButtons.forEach((radio) => {
            if (radio.checked) allUnchecked = false;
          });

        })
        .finally(() => {
          this.checkSubmit();
        });
    });

    // Address unselect handler.
    Dom.addEventListener(`${this.identifier}:address:unselect`, () => {
      this.addressIdValue = null;

      this.checkSubmit();
    });

    // Pick up point option change handler.
    Dom.addEventListener(`${this.identifier}:pick_up_point:change`, (event) => {
      this.pickUpPointIdValue = event.detail.id;
      this.pickUpPointAddressValue = event.detail.address;

      this.checkSubmit();
    });

    // Pick up point unselect handler.
    Dom.addEventListener(`${this.identifier}:pick_up_point:unselect`, () => {
      this.pickUpPointIdValue = '';
      this.pickUpPointAddressValue = '';

      this.checkSubmit();
    });

    // Payment option change handler.
    Dom.addEventListener(`${this.identifier}:payment:change`, (event) => {
      this.paymentValue = event.detail.value;
      this.paymentSaveValue = event.detail.save;
      this.paymentCreditCardId = null;

      this.checkSubmit();
    });

    // Payment option credit card name change handler.
    Dom.addEventListener(`${this.identifier}:payment:credit_card:change`, (event) => {
      this.paymentCreditCard = event.detail.value;
    });

    // Payment option credit card change handler.
    Dom.addEventListener(`${this.identifier}:payment:credit_card:id:change`, (event) => {
      this.paymentCreditCardId = event.detail.value;
      this.paymentSaveValue = event.detail.save;
    });

    // Payment option save credit card change handler.
    Dom.addEventListener(`${this.identifier}:payment:credit_card:save:change`, (event) => {
      this.paymentSaveValue = event.detail.value;
    });

    // Shipping date option change handler.
    Dom.addEventListener(`${this.identifier}:shipping_date:change`, (event) => {
      this.shippingDateModeValue = event.detail.value;

      this.checkSubmit();
    });

    // Shipping date option change handler.
    Dom.addEventListener(`${this.identifier}:shipping_date:date:change`, (event) => {
      this.shippingDateDateValue = event.detail.value;

      this.checkSubmit();
    });

    // this.couponApply(undefined, this.couponInputTargets[0]);
  }

  /**
   * Get cart controller.
   * @return {Controller}
   */
  get cartController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller$="header--cart"]'),
      'header--cart',
    );
  }

  /**
   * Check if selected payment is credit card.
   * @return {boolean}
   */
  get paymentIsCreditCard() {
    return this.paymentValue?.includes('credit_card');
  }

  setTotalAmount(total) {
    this.totalTargets.forEach((target) => {
      target.textContent = total;
    });
  }

  get promotionOpt() {
    return this.promotionOptValue !== undefined
      ? this.promotionOptValue
      : this.promotionAvailableValue;
  }

  set promotionOpt(value) {
    this.promotionOptValue = value;
  }

  get couponCode() {
    return this.couponCodeValue;
  }

  set couponCode(value) {
    this.couponCodeValue = value;
  }

  /**
   * Update promotion opt targets.
   */
  updatePromotionOpt(event) {
    this.promotionOptTargets.forEach((target) => {
      target.checked = event.target.checked;
    });
  }

  /**
   * Apply coupon code and promotion opt.
   */
  couponApply(event, target) {
    const targetElement = target || event.target;
    const couponTarget = targetElement.closest('[data-checkout-target="coupon"]');

    this.couponCode = couponTarget.querySelector('input[name="coupon"]').value;
    this.promotionOpt = couponTarget.querySelector('input[name="promotion_opt"]')
      ? couponTarget.querySelector('input[name="promotion_opt"]').checked
      : this.promotionAvailableValue;

    this.refreshShippingData();
  }

  /**
   * Set coupon code.
   */
  setCouponAmount(coupon) {
    if (coupon === undefined) {
      this.couponTargets.forEach((target) => target.removeAttribute('data-coupon'));
    } else {
      this.couponTargets.forEach((target) => {
        target.setAttribute('data-coupon', coupon?.amount ? 'valid' : 'invalid');
        target.setAttribute('data-coupon-reason', this.promotionOpt && this.promotionApplicableValue ? 'promotion' : 'invalid');
      });
      this.couponCode = coupon ? coupon.code : undefined;
      this.promotionOpt = coupon ? false : this.promotionOpt;
    }
    this.couponTargets.forEach((target) => {
      const targetCode = target.querySelector('[data-code]');
      if (targetCode) {
        targetCode.textContent = coupon?.code || '';
      }
    });
    this.couponTargets.forEach((target) => {
      const targetAmount = target.querySelector('[data-amount]');
      if (targetAmount) {
        targetAmount.textContent = coupon?.amount || '0';
      }
    });

    if (coupon) {
      this.couponTargets
        .find((target) => target.getAttribute('data-device') === 'mobile')
        .querySelector('[data-controller="components--dropdown"]')
        ?.remove();
      this.couponTargets
        .filter((target) => target.getAttribute('data-device') === 'desktop')
        .forEach((target) => {
          const targetInput = target.querySelector('[data-input] input');
          if (targetInput) {
            targetInput.disabled = true;
          }
          target.querySelector('[data-input] button')?.remove();
        });
      this.promotionOptTargets.forEach((target) => {
        target.disabled = true;
      });
    }
  }

  /**
   * Set new totals and default pick up point when needed.
   */
  refreshShippingData() {
    const params = new URLSearchParams();
    params.set('shipping_company', this.shippingCompanyValue);
    params.set('shipping_mode', this.shippingModeValue);
    params.set('coupon', this.couponCode);
    params.set('promotion_opt', this.promotionOpt);

    this.submitTarget.setAttribute('disabled', '');
    Spinner.show(this.submitTarget);

    let pendingRequest = 1;
    if (!this.pickUpPointIdValue && this.shippingModeValue === 'collection_point') {
      pendingRequest += 1;
    }

    Request.get(`/checkout/totals?${params.toString()}`, { contentType: 'text/html' })
      .then(({ response }) => {
        this.shippingAmountTarget.textContent = response.shipping_amount;

        if (this.couponCode?.length) {
          if (response.coupon) {
            this.couponCode = response.coupon;
          }
          this.setCouponAmount(response.coupon);
        } else {
          this.setCouponAmount(undefined);
        }

        if (response.shipping_options) {
          response.shipping_options.forEach((option) => {
            const shippingOptionTarget = document
              .querySelector(`[name="shipping"][value="${option.id}"]`)
              ?.closest('[data-components--single-option-list-target="option"]');
            if (shippingOptionTarget) {
              shippingOptionTarget.querySelector('[data-value]').textContent = option.amount;

              if (option.remark?.length) {
                const shippingOptionRemarkTarget = shippingOptionTarget.querySelector('[data-remark]');
                if (shippingOptionRemarkTarget) {
                  shippingOptionRemarkTarget.textContent = `(${option.remark})`;
                }
                const detailsShippingMethodDesc = document
                  .querySelector(`[data-checkout-target="detailsShippingMethodDesc"][data-shipping-method="${option.id}"]`);
                if (detailsShippingMethodDesc) {
                  detailsShippingMethodDesc.textContent = `(${option.remark})`;
                }
              }
            }
          });
        }

        this.totalTargets.forEach((target) => {
          target.textContent = response.total;
        });
        if (this.hasTotalEurTarget) {
          this.totalEurTarget.textContent = response.total_eur;
        }
      })
      .finally(() => {
        pendingRequest -= 1;
        if (pendingRequest === 0) {
          Spinner.remove();
        }
        this.checkSubmit();
      });

    if (!this.pickUpPointIdValue && this.shippingModeValue === 'collection_point') {
      Request.get(`/checkout/default_pick_up_point?shipping_company=${this.shippingCompanyValue}`)
        .then(({ response }) => {
          if (response) {
            this.pickUpPointIdValue = response.id;
            this.pickUpPointAddressValue = response.fulladdress;

            if (this.hasPickUpPointTarget) {
              this.pickUpPointTarget
                .querySelector('[data-controller="pick-up-point"]')
                .setAttribute('data-pick-up-point-postal-code-value', response.postcode);
              this.pickUpPointTarget.querySelector('[data-address-line-1]').textContent = response.name.toLowerCase();
              this.pickUpPointTarget.querySelector('[data-address-line-2]').textContent = `
                ${response.street.toLowerCase()}, ${response.postcode.toLowerCase()}, ${response.city.toLowerCase()}
              `;
              this.pickUpPointTarget
                .querySelector('[data-pick-up-point-target="launcher"]')
                .setAttribute('data-completed', 'true');
            }
          }
        })
        .finally(() => {
          pendingRequest -= 1;
          if (pendingRequest === 0) {
            Spinner.remove();
          }
          this.checkSubmit();
        });
    }
  }

  /**
   * Enable/disable submit button.
   */
  checkSubmit() {
    if (
      this.shippingMethodValue
      && (
        (this.shippingModeValue === 'home' && this.addressIdValue)
        || (this.shippingModeValue === 'collection_point' && this.addressIdValue && this.pickUpPointIdValue && this.pickUpPointAddressValue)
      )
      && (
        this.paymentValue && this.paymentValue !== '-'
      )
      && (
        this.shippingDateModeValue === 'normal'
        || (this.shippingDateModeValue === 'custom' && this.shippingDateDateValue)
      )
    ) {
      this.submitTarget.removeAttribute('disabled');
    } else {
      this.submitTarget.setAttribute('disabled', '');
    }

    // Check address field.
    const requiredFieldAddress = this.requiredFieldsTarget.querySelector('[data-section="address"]');
    if (!this.addressIdValue) {
      const addressHeaderElement = this.element.querySelector('[data-required-section="address"]');
      const addressHeaderVisibleElement = Array.from(addressHeaderElement.children)
        .filter((element) => element.offsetParent !== null)[0];
      const addressHeaderText = addressHeaderVisibleElement
        ? addressHeaderVisibleElement.textContent
        : addressHeaderElement.textContent;
      requiredFieldAddress.textContent = `${addressHeaderText}: ${this.requiredFieldsTarget.getAttribute('data-error')}`;
      requiredFieldAddress.setAttribute('data-filled', 'false');
    } else {
      requiredFieldAddress.setAttribute('data-filled', 'true');
    }

    // Check pick up point field.
    // const requiredFieldpickUpPoint = this.requiredFieldsTarget.querySelector('[data-section="pickUpPoint"]');
    // if (this.shippingModeValue === 'collection_point' && !this.pickUpPointIdValue) {
    //   const shippingOptions = document
    //     .querySelectorAll('[data-controller="components--single-option-list"][data-input-name="shipping"] input[name="shipping"]');
    //   const shippingOptionChecked = Array.from(shippingOptions).find((target) => target.checked);
    //   if (shippingOptionChecked && this.shippingModeValue !== shippingOptionChecked.value.split('~')[1]) {
    //     setTimeout(() => {
    //       Dom.triggerEvent(shippingOptionChecked, 'change');
    //     }, 500);
    //   }
    //
    //   requiredFieldpickUpPoint.textContent = `
    //     ${this.element.querySelector('[data-required-section="pickUpPoint"]').textContent.trim()}: ${this.requiredFieldsTarget.getAttribute('data-error')}
    //   `;
    //   requiredFieldpickUpPoint.setAttribute('data-filled', 'false');
    // } else {
    //   requiredFieldpickUpPoint.setAttribute('data-filled', 'true');
    // }

    // Check payment field.
    const requiredFieldPayment = this.requiredFieldsTarget.querySelector('[data-section="payment"]');
    if (!this.paymentValue || this.paymentValue === '-') {
      const paymetOptions = document
        .querySelectorAll('[data-controller="components--single-option-list components--payment"] input[name="payment"]');
      const paymentOptionChecked = Array.from(paymetOptions).find((target) => target.checked);
      if (paymentOptionChecked) {
        setTimeout(() => {
          Dom.triggerEvent(paymentOptionChecked, 'change');
        }, 500);
      }

      requiredFieldPayment.textContent = `
        ${this.element.querySelector('[data-required-section="payment"]').textContent.trim()}: ${this.requiredFieldsTarget.getAttribute('data-error')}
      `;
      requiredFieldPayment.setAttribute('data-filled', 'false');
    } else {
      requiredFieldPayment.setAttribute('data-filled', 'true');
    }

    // Show/hide required fields notice.
    if (Array.from(this.requiredFieldsTarget.children).filter((element) => element.getAttribute('data-filled') === 'false').length) {
      this.requiredFieldsTarget.setAttribute('data-filled', 'false');
    } else {
      this.requiredFieldsTarget.setAttribute('data-filled', 'true');
    }
  }

  /**
   * Fill checkout form and submit.
   */
  submit() {
    this.appendInput('shipping_method', this.shippingMethodValue);
    this.appendInput('gateway_method', this.paymentValue);
    this.appendInput('order[address_id]', this.addressIdValue);
    this.appendInput('order[pickup_point_id]', this.pickUpPointIdValue);
    this.appendInput('order[pickup_point_address]', this.pickUpPointAddressValue);
    this.appendInput('order[shipping_mode]', this.shippingDateModeValue);
    this.appendInput('order[shipping_date]', this.shippingDateDateValue);
    if (this.paymentIsCreditCard) {
      if (this.paymentSaveValue === 'true') {
        this.appendInput('save_credit_card', '1');
      } else {
        this.appendInput('save_credit_card', '0');
      }
      if (this.paymentCreditCard) {
        this.appendInput('credit_card_name', this.paymentCreditCard);
      }
      if (this.paymentCreditCardId) {
        this.appendInput('credit_card_id', this.paymentCreditCardId);
      }
    }
    if (this.couponCode) {
      this.appendInput('coupon_code', this.couponCode);
    }
    this.appendInput('promotion_opt', this.promotionOpt);

    // Dispatch checkout submit.
    Dom.dispatchEvent('checkout:submit', {
      payment: this.paymentValue,
    });

    this.formTarget.submit();
  }

  /**
   * Append checkout form input.
   */
  appendInput(name, value) {
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', name);
    input.setAttribute('value', value);
    this.formTarget.appendChild(input);
  }
}
