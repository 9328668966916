import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['trigger'];

  connect() {
    this.trigger.addEventListener('click', this.toggle.bind(this));
  }

  /**
   * Get trigger target or first child.
   */
  get trigger() {
    return this.hasTriggerTarget
      ? this.triggerTarget
      : this.element.children[0];
  }

  /**
   * Toggle dropdown.
   */
  toggle() {
    this.element.hasAttribute('data-active') ? this.close() : this.open();
  }

  /**
   * Open dropdown.
   */
  open() {
    this.closeAll();
    this.element.setAttribute('data-active', '');
  }

  /**
   * Close dropdown.
   */
  close(dropdown = this.element) {
    dropdown.removeAttribute('data-active');
  }

  /**
   * Close all "autoclose" dropdowns.
   */
  closeAll() {
    document.querySelectorAll('[data-controller$="dropdown"][data-active]:not([data-dropdown-autoclose="false"])')
      ?.forEach(this.close);
  }
}
