const defaultConfig = require('tailwindcss/defaultConfig');
const defaultTheme = require('tailwindcss/defaultTheme');
const plugin = require('tailwindcss/plugin');

// Extend default spacing.
const extendedSpacing = {
  '0-125': '.125rem', // 2px
  '1-1/2': '.375rem', // 6px
  '2-625': '0.625rem', // 10px
  '3-812': '0.8125rem', // 13px
  '3-875': '0.875rem', // 14px
  '3-938': '0.938rem', // 15px
  '4-125': '1.125rem', // 18px
  '4-187': '1.1875rem', // 19px
  '5-375': '1.375rem', // 22px
  '5-438': '1.438rem', // 23px
  7: '1.75rem', // 28px
  '7-1/2': '1.875rem', // 30px
  9: '2.25rem', // 36px
  11: '2.75rem', // 44px
  '13-1/2': '3.375rem', // 54px
  14: '3.5rem', // 56px
  15: '3.75rem', // 60px
  18: '4.5rem', // 72px
  25: '6.25rem', // 100px
  28: '7rem', // 112px
  '31-875': '7.875rem', // 126px
  38: '9.5rem', // 152px
  41: '10.25rem', // 164px
  49: '12.25rem', // 196px
  50: '12.5rem', // 200px
  60: '15rem', // 240px
  66: '16.5rem', // 264px
  75: '18.75rem', // 300px
  80: '20rem', // 320px
  85: '21.25rem', // 340px
  93: '23.25rem', // 372px
  100: '25rem', // 400px
  111: '27.75rem', // 444px
  117: '29.25rem', // 468px
  '1/2': '50%', // 50%
  full: '100%', // 100%
  '13%': '13%', // 13%
  '15%': '15%', // 15%
  '22%': '22%', // 22%
  '55%': '55%', // 55%
  '70%': '70%', // 70%
  '130%': '130%', // 130%
  ...defaultConfig.theme.spacing,
};

// Generate negatives for spacing.
const extendedSpacingWithNegatives = { ...extendedSpacing };
Object.entries(extendedSpacingWithNegatives).forEach(([key, value]) => {
  extendedSpacingWithNegatives[`-${key}`] = `-${value}`;
});

// Extend default variants.
const customVariants = {
  backgroundColor: ['group-data-active'],
  borderColor: ['group-hover'],
  borderRadius: ['group-data-active'],
  display: ['group-data-active'],
  margin: ['first', 'p', 'list'],
  padding: ['group-data-active', 'first', 'list'],
  textColor: ['group-hover', 'group-data-active', 'active'],
  textDecoration: ['group-data-active', 'active'],
  opacity: ['responsive', 'hover', 'focus', 'disabled'],
  inset: ['responsive', 'hover', 'focus'],
  fontSize: ['p'],
  fontWeight:  ['a'],
  lineHeight: ['list'],
  listStylePosition: ['list'],
  listStyleType: ['list'],
};
const extendedVariants = { ...defaultConfig.variants };
Object.entries(customVariants).forEach(([key, value]) => {
  extendedVariants[key] = extendedVariants[key].concat(value);
});

module.exports = {
  purge: {
    enabled: process.env.RAILS_ENV != 'development',
    content: [
      './app/views/**/*.erb',
      './app/helpers/**/*.rb',
      './app/decorators/**/*.rb',
      './app/javascript/*.js',
      './app/javascript/*.scss'
    ]
  },
  darkMode: false, // or 'media' or 'class'
  theme: {
    screens: {
      xs: '448px',
      ...defaultTheme.screens,
    },
    fontFamily: {
      sans: ['Roboto', 'sans-serif'],
      serif: ['Manuale', 'serif'],
      mono: ['Roboto Mono', 'monospace'],
      body: ['Lato', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'],
    },
    boxShadow: {
      'outline-0-125-gray-300': '0 0 0 2px rgba(241, 241, 242, 1)',
      'outline-0-125-gray-500': '0 0 0 2px rgba(206, 208, 211, 1)',
    },
    extend: {
      fontSize: {
        default: '16px',
        'xs-big': '.8125rem',
        'sm-big': '.9375rem',
        8: '2rem',
        ...extendedSpacing,
      },
      spacing: extendedSpacingWithNegatives,
      colors: {
        'silver': {
          '200': '#f7f8f8',
          '300': '#f1f1f2',
          '500': '#ced0d3',
          '600': '#6d717c',
          '700': '#3c4251',
          '900': '#0b1325',
        },
        'teal': {
          '50': '#f5fcf9',
          '100': '#eaf9f3',
          '200': '#cbf1e0',
          '300': '#ace8ce',
          '320': '#DCEEE0',
          '350': '#83B685',
          '370': '#67B784',
          '400': '#66cc99',
          '500': '#30c584',
          '600': '#2bb177',
          '700': '#359f85',
          '800': '#308f78',
          '900': '#287764',
        },
        'gray': {
          '10': '#EEEEEE',
           30: '#f1f1f2',
           40: '#f7f8f8',
          500: '#ced0d3',
          518: '#9ea1a8',
          600: '#6d717c',
          700: '#3c4251',
          800: '#333333',
          900: '#0b1325',
        },
        'blue-sky': {
          '100': '#5bc0de',
          '200': '#46b8da',
          '300': '#39b3d7',
          '400': '#269abc'
        },
        red: {
          25: '#f2dede',
          50: '#f1a3a3',
          100: '#f56c6c',
          200: '#f84e4e',
          300: '#ff2847',
          500: '#A94442',
          600: '#d9534f',
          700: '#d2322d',
        },
        green: {
          100: '#EAF9F3',
          400: '#00d9a3',
          500: '#30c584',
        },
        white: '#ffffff',
      },
      lineHeight: {
        0: 0,
        'extra-tight': '1.2',
        10: '2.5rem',
      },
      spacing: {
        '1/3': '33.333333%',
        '3/20': '15%',
        38: '9.5rem', // 152px
        '5px': '5px',
        '10px': '10px',
        '20px': '20px',
        '17r': '17.5rem',
        ...extendedSpacingWithNegatives,
      },
      keyframes: {
        fadein: {
          from: { opacity: 0 },
          to: { opacity: 1 }
        },
        fadeout: {
          from: { opacity: 1 },
          to: { opacity: 0 }
        },
        'rotatey--90-0': {
          from: {
            transform: 'rotateY(-90deg)'
          },
          to: {
            transform: 'rotateY(0deg)'
          }
        },
        'rotatey-0--90': {
          from: {
            transform: 'rotateY(0deg)'
          },
          to: {
            transform: 'rotateY(-90deg)'
          }
        },
      },
      animation: {
        fadein: 'fadein 2s ease-out',
        'fadein-fast': 'fadein .3s ease-out',
        'fadeout-fast': 'fadeout .3s ease-out',
        'rotatey--90-0-opacity-0-100': `
          rotatey--90-0 .15s linear normal forwards,
          fadein .15s linear normal forwards
        `,
        'rotatey-0--90-opacity-100-0': `
          rotatey-0--90 .15s linear normal forwards,
          fadeout .15s linear normal forwards
        `
      },
      typography: (theme) => ({
        DEFAULT: {
          css: {
            a: {
              color: theme('colors.teal.500'),
              textDecoration: 'inherit',
              '&:hover': {
                color: theme('colors.teal.600'),
                textDecoration: 'underline',
              },
            }
          }
        }
      }),
      width: {
        '65': '20rem',
        '66': '29rem',
        '67': '40rem',
        '17r': '17rem',
        '140': '140px',
        '150': '150px',
        '144': '144px',
        '200': '200px',
        '230': '230px',
        '2400': '240px',
        '300': '300px',
        '327': '327.8px',
      },
      height: {
        '65': '20rem',
        '66': '29rem',
        '67': '40rem',
        '140': '140px',
        '150': '150px',
        '144': '144px',
        '200': '200px',
        '230': '230px',
        '2400': '240px',
        '300': '300px',
        '327': '327.8px',
      },
      inset: {
        '8': '2rem',
        '12': '3rem',
        '24': '6rem',
        '40': '10rem',
        ...extendedSpacingWithNegatives,
      },
      minWidth: {
        '14r': '14rem',
        '140': '140px',
        '150': '150px',
        '144': '144px',
        '300': '300px',
        '327': '327.8px',
        ...extendedSpacingWithNegatives,
      },
      minHeight: {
        '144': '144px',
        '327': '327.8px',
      },
      maxWidth: {
        '140': '140px',
        '150': '150px',
        '144': '144px',
        '230': '230px',
        '300': '300px',
        '327': '327.8px',
        '4/5': '80%',
        'full': '100%',
        ...extendedSpacingWithNegatives,
      },
      maxHeight: {
        '144': '144px',
      },
      letterSpacing: {
        '031': '.03125rem', // 0.5px
      },
      opacity: {
        60: '.6',
        72: '.72',
        82: '.82',
        90: '.9',
      },
    }
  },
  variants: extendedVariants,
  content: [
    "./node_modules/flowbite/**/*.js"
  ],
  plugins: [
    require('flowbite/plugin'),
    require('@tailwindcss/forms'),
    require('@tailwindcss/typography'),
    // Group variant to apply when parent "group" has an "active" data attribute.
    plugin(({ addVariant, e }) => {
      addVariant('group-data-active', ({ modifySelectors, separator }) => {
        modifySelectors(({ className }) => `
            .group[data-active] > .${e(`group-data-active${separator}${className}`)},
            .group[data-active] > [data-components--dropdown-target="trigger"] .${e(`group-data-active${separator}${className}`)}
          `);
      });
    }),
    // Variant that targets children <a> tags.
    plugin(({ addVariant, e }) => {
      addVariant('a', ({ modifySelectors, separator }) => {
        modifySelectors(({ className }) => `
            .${e(`a${separator}${className}`)} a
          `);
      });
    }),
    // Variant that targets children <p> tags.
    plugin(({ addVariant, e }) => {
      addVariant('p', ({ modifySelectors, separator }) => {
        modifySelectors(({ className }) => `
            .${e(`p${separator}${className}`)} p
          `);
      });
    }),
    // Variant that targets children <ul|ol> tags.
    plugin(({ addVariant, e }) => {
      addVariant('list', ({ modifySelectors, separator }) => {
        modifySelectors(({ className }) => `
            .${e(`list${separator}${className}`)} ul,
            .${e(`list${separator}${className}`)} ol
          `);
      });
    }),
    require('@tailwindcss/line-clamp')
  ],
}
