import { Controller } from "@hotwired/stimulus"
import Dom from '../../helpers/dom';

export default class extends Controller {
  static values = {
    url: String
  };
  static targets = [ "form", "user", "query", "btnChangeUser", "userDataInvoiceSeller"]
  static currentUserIdInvoice
  static currentUserQueryInvoice
  static currentUserDataInvoice
  initialize() {
    console.log("controller initialized")
  }
  connect() {
    console.log("controller connected")
    this.getResults(this.userTarget.value)
  }

  searchUser(event){
    clearTimeout( this.timeout )
    this.userTarget.value = '';
    this.timeout = setTimeout(() => {
      this.getResults(event.target.value)
    }, 500)
  }
  getId(event){
    if(event.inputType == "insertReplacementText" || event.inputType == null) {
      let value_id = document.querySelector(`option[value="${event.target.value}"]`)
      if ( value_id !== null){
        this.userTarget.value = value_id.dataset.value
        Dom.dispatchEvent('billing:has_address', {
          has_billing_address: value_id.dataset.hasbillingaddress,
        });
      } else {
        Dom.dispatchEvent('billing:has_address', {
          has_billing_address: false,
        });
      }
    }
  }
  getResults(param){
    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')?.content,
      },
      body: new URLSearchParams({ query_search: param })
    }).then(r => r.text())
      .then(html => {
        Turbo.renderStreamMessage(html)
      })
  }
  searchUserInvoice(event){
    clearTimeout( this.timeout )
    this.timeout = setTimeout(() => {
      this.getResults(event.target.value)
    }, 500)
  }
  getIdUserInvoice(event){
    if(event.inputType == "insertReplacementText" || event.inputType == null) {
      let value_id = document.querySelector(`option[value="${event.target.value}"]`)
      console.log('value_id', value_id)
      if ( value_id !== null){
        this.userDataInvoiceSellerTarget.innerHTML = `<strong>Usuario:</strong> ${value_id.text} (${value_id.value})`
        this.userTarget.value = value_id.dataset.value
      }
    }
  }
  activeChangeUserInvoiceSeller() {
    if (this.queryTarget.disabled){
      this.queryTarget.disabled = false;
      this.btnChangeUserTarget.innerText = 'Cancelar'
      this.currentUserIdInvoice = this.userTarget.value
      this.currentUserQueryInvoice = this.queryTarget.value
      this.currentUserDataInvoice = this.userDataInvoiceSellerTarget.innerHTML
    } else {
      this.queryTarget.disabled = true;
      this.btnChangeUserTarget.innerText = 'Asignar Usuario'
      this.userTarget.value = this.currentUserIdInvoice
      this.queryTarget.value = this.currentUserQueryInvoice
      this.userDataInvoiceSellerTarget.innerHTML = this.currentUserDataInvoice

    }
  }
}
