import { Controller } from '@hotwired/stimulus';
import Dom from 'helpers/dom';

export default class extends Controller {
  static targets = ['backdrop', 'dialog'];

  connect() {
    // Ensure no overlay exists before store current scroll.
    if (!document.body.classList.contains('modal-open')) {
      // Store scroll position to restore it when modal closes.
      document.body.setAttribute('data-scroll-position', document.documentElement.scrollTop);
      document.body.style.top = `${document.documentElement.scrollTop * -1}px`;
      // Disable page scroll.
      document.body.classList.add('modal-open');
    }
  }

  /**
   * Close and remove modal.
   */
  close() {
    // Apply animation.
    this.backdropTarget.classList.add('animate-fadeout-fast');
    this.dialogTarget.classList.add('animate-rotatey-0--90-opacity-100-0');

    // Wait for animation to finish before remove the modal.
    setTimeout(() => {
      // Remove modal.
      const modal = this.element.closest('#twp') || this.element;
      modal.remove();

      if (!this.anyModalOpen()) {
        // Enable page scroll.
        document.body.classList.remove('modal-open');
        // Restore scroll position.
        document.body.style.top = '0px';
        window.scrollTo(0, document.body.getAttribute('data-scroll-position'));
        document.body.getAttribute('data-scroll-position');
      }

      Dom.dispatchEvent('modal:closed');
    }, 200);
  }

  /**
   * Close and remove modal based on event.
   */
  closeDeferred(event) {
    const target = event.currentTarget;

    // Get "close" event name and listen to it. Otherwise, close modal without delay.
    const modalCloseOn = target.getAttribute('data-modal-close-on');
    if (modalCloseOn) {
      this.element.addEventListener(`modal:close:${modalCloseOn}`, this.close.bind(this));
    } else {
      this.close();
    }
  }

  /**
   * Accept event. Used by "confirm" modals.
   */
  accept(event) {
    // Get confirmation target.
    const confirmationId = event.currentTarget.getAttribute('data-confirmation-id');
    const target = document.querySelector(
      `[data-confirmation-id="${confirmationId}"]:not([data-action$="modal#accept"])`,
    );
    if (target) {
      // Remove confirmation requirement, close modal and fire target action.
      target.setAttribute('data-confirmation', 'false');
      this.close();
      Dom.triggerEvent(target, 'click');
    }
  }

  /**
   * Check if there are other modals open.
   * @return {boolean}
   */
  anyModalOpen() {
    return document.querySelector(`[data-controller="${this.identifier}"]`) != null;
  }
}
