import { Controller } from '@hotwired/stimulus';
import Splide from '@splidejs/splide';

export default class extends Controller {
  static values = { config: Object }

  connect() {
    const config = {
      type: 'loop',
      arrowPath: 'M 15 9.023438 L 26.667969 20.691406 L 15 32.355469 L 17.355469 34.714844 L 31.378906 20.691406 L 17.355469 6.667969 Z M 15 9.023438',
      ...this.configValue,
    };

    new Splide(this.element, config).mount();
  }
}
