import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['kind', 'minExpense']

  connect() {
    const kindsWithoutMinExpense = ['pickup', 'return'];

    this.kindTarget.addEventListener('change', (e) => {
      if (kindsWithoutMinExpense.includes(e.target.value)) {
        this.disableMinExpense();
      } else {
        this.enableMinExpense();
      }
    });

    if (kindsWithoutMinExpense.includes(this.kindTarget.value)) {
      this.disableMinExpense();
    }
  }

  disableMinExpense() {
    this.minExpenseTarget.disabled = true;
    this.minExpenseTarget.value = null;
  }

  enableMinExpense() {
    this.minExpenseTarget.disabled = false;
  }
}
