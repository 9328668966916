import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['trigger', 'collapseToggle', 'collapseToggleTrigger'];
  static collapse;

  connect() {
    // set the target element that will be collapsed or expanded (eg. navbar menu)
  }

  /**
   * Toggle collapse.
   */
  toggle() {
    const targetEl = this.collapseToggleTarget;
    const triggerEl = this.collapseToggleTriggerTarget;
    const options = {
      triggerEl: triggerEl,
      onCollapse: () => {
        console.log('element has been collapsed')
      },
      onExpand: () => {
        console.log('element has been expanded')
      },
      onToggle: () => {
        console.log('element has been toggled')
      }
    };
    this.collapse = new Collapse(targetEl, options);
    this.collapse.toggle();
  }

}
