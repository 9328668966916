import { Controller } from '@hotwired/stimulus';
import Dom from '../../helpers/dom';

export default class extends Controller {
  static targets = ["addressForm", "contentInput", "checkbox"];
  static values = {
    street: String,
    city: String,
    postcode: String,
    defaultId: Number
  };

  connect() {
    document.addEventListener('billing:has_address', (event) => {
      if (event.detail.has_billing_address === 'true') {
        document.querySelector(`#has_billing_address`).checked = event.detail.has_billing_address;
        this.toggle();
      } else {
        document.querySelector(`#has_billing_address`).checked = '';
      }
    });

    this.set();
    this.setValues();
  }

  toggle() {
    if (this.checkboxTarget.checked) {
      this.addressFormTarget.setAttribute("hidden", "");
      this.setAddressDefault(this.streetValue, this.cityValue, this.postcodeValue);
    } else {
      this.addressFormTarget.removeAttribute("hidden");

      Dom.dispatchEvent('billing:set_has_address', {
        has_billing_address: 'false',
      });
    }
  }

  get checkboxTarget() {
    return this.targets.find("checkbox");
  }

  setAddressDefault(street, city, postcode) {
    const streetInput = this.addressFormTarget.querySelector('#proposal_street');
    const cityInput = this.addressFormTarget.querySelector('#proposal_city');
    const postcodeInput = this.addressFormTarget.querySelector('#proposal_postcode');

    this.setBillingAddress();

    if (streetInput) {
      streetInput.value = street;
    }

    if (cityInput) {
      cityInput.value = city;
    }

    if (postcodeInput) {
      postcodeInput.value = postcode;
    }
  }

  set() {
    if (typeof(this.checkboxTarget) === 'undefined') {
      return;
    }

    if (this.checkboxTarget.checked) {
      this.addressFormTarget.setAttribute("hidden", "");
      this.contentInputTarget.removeAttribute("hidden");
      this.setBillingAddress();
    } else {
      this.contentInputTarget.setAttribute("hidden", "");
      this.addressFormTarget.removeAttribute("hidden");
    }
  }

  setValues() {
    const streetInput = this.addressFormTarget.querySelector('#proposal_street');
    const cityInput = this.addressFormTarget.querySelector('#proposal_city');
    const postcodeInput = this.addressFormTarget.querySelector('#proposal_postcode');

    if (streetInput) {
      this.streetValue = streetInput.value;
    }

    if (cityInput) {
      this.cityValue = cityInput.value;
    }

    if (postcodeInput) {
      this.postcodeValue = postcodeInput.value;
    }
  }

  setBillingAddress() {
    // Dispatch billing address selection if there is an auto selected address.
    if (this.defaultIdValue) {
      Dom.dispatchEvent('address:select', {
        address_id: this.defaultIdValue,
      });
    }
  }

  /**
   * Get address controller.
   * @return {Controller}
   */
  get addressController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller$="address"]'),
      'address',
    );
  }
}


