// Modern mobile browsers open external URLs in Content Tabs inside the PWA
// instead of opening the native browser. In some links (social networks),
// we want to launch the native app if it is installed in the device.
// This controller replaces those links with an Android Intent, including a
// fallback to the default URL when the app is not installed.
// https://developer.chrome.com/docs/multidevice/android/intents/

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'link' ]

  connect() {
    /*
    Checking the display mode we can know whether the web is loaded as PWA.
    This does not work in IOS (it uses the non-standard navigator.standalone)
    but anyway using intent for links is only available on Android, so this serves
    as a double check: it is loaded as PWA and it runs on Android
    */
    if(matchMedia('(display-mode: standalone)').matches) {
      this.placeAllIntents();
    }
  }

  placeIntent(a) {
    if (!a?.dataset?.intentUri) return;

    let uri = a.dataset.intentUri;
    let fallback = a.href;
    let intentPackage = a.dataset.intentPackage;
    let [scheme, relativeUrl] = uri.split('://');
    let intent = `intent://${relativeUrl}#Intent;scheme=${scheme};action=android.intent.action.VIEW;`
    if (intentPackage) {
      intent += `package=${intentPackage};`
    }
    if (fallback) {
      intent += `S.browser_fallback_url=${encodeURIComponent(fallback)};`
    }
    intent += 'end';
    a.href = intent;
  }

  placeAllIntents() {
    this.linkTargets?.forEach(this.placeIntent);
  }
}
