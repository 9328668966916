export default {

  /**
   * Get csrf token.
   * @return {string}
   */
  get csrfToken() {
    return document.querySelector('meta[name=csrf-token]')?.content;
  },

  /**
   * Ajax request.
   * @param {string} url Request url.
   * @param {object} options Request options.
   *   Valid options:
   *     method: {string} HTTP Method.
   *     cache: {string} Cache mode.
   *     body: {object|string} Data.
   *     accept: {string} Expected media type.
   *     contentType: {string} Resource media type.
   *     customHeaders: {object} Additional HTTP headers.
   * @return {Promise}
   */
  request(url, options = {}) {
    const requestOptions = {
      method: options.method || 'GET',
      headers: {
        ...{
          'X-CSRF-Token': this.csrfToken,
          Accept: options.accept || options.contentType || 'application/json',
          'Content-Type': options.contentType || 'application/json',
        },
        ...options.customHeaders,
      },
      cache: options.cache || 'no-cache',
      credentials: 'same-origin', // https://github.com/github/fetch#sending-cookies
    };
    if (options.body) {
      requestOptions.body = typeof options.body === 'string' ? options.body : JSON.stringify(options.body);
    }

    return new Promise((resolveRequest, rejectRequest) => {
      fetch(url, requestOptions)
        .then((response) => new Promise((resolveResponse, rejectResponse) => {
          const promise = response.headers.get('Content-Type').includes('text/html')
            ? response.text()
            : response.json();

          return promise
            .then((data) => {
              resolveResponse({
                ok: response.ok,
                status: response.status,
                data,
              });
            })
            .catch(() => {
              // In case that resquest's response fails during parse,
              // "response" will be an empty object.
              // [AppSignal for JavaScript]
              rejectResponse({});
            });
        }))
        .then((response) => {
          if (response.ok) {
            resolveRequest({
              status: response.status,
              response: response.data,
            });
          } else {
            // Not successful response (status is not between 200-299).
            rejectRequest({
              status: response.status,
              error: response.data,
            });
          }
        })
        .catch(() => {
          // [AppSignal for JavaScript]
          rejectRequest({});
        });
    });
  },

  get(url, options) {
    return this.request(url, options);
  },

  post(url, options) {
    return this.request(url, { ...options, ...{ method: 'POST' } });
  },

  put(url, options) {
    return this.request(url, { ...options, ...{ method: 'PUT' } });
  },

  delete(url, options) {
    return this.request(url, { ...options, ...{ method: 'DELETE' } });
  },

  /**
   * Redirect.
   * @param {string} url New URL,
   * @param {boolean} [addHistory] Add URL to the browser's history.
   * @param {number} [delay] Delay in milliseconds. By default 0 milliseconds.
   */
  redirect(url, addHistory, delay) {
    setTimeout(() => {
      if (addHistory === false) {
        window.location.replace(url);
      } else {
        window.location.href = url;
      }
    }, delay || 0);
  },

  /**
   * Reload current page.
   * @param {number} [delay] Delay in milliseconds. By default 0 milliseconds.
   */
  reload(delay) {
    this.redirect(window.location.href, false, delay);
  },
};
