import { Controller } from '@hotwired/stimulus';
import Dom from 'helpers/dom';

export default class extends Controller {
  static targets = ['option'];

  /**
   * Get single option list controller.
   * @return {Controller}
   */
  get singleOptionListController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      'components--single-option-list',
    );
  }

  /**
   * Get option name.
   * @return {string}
   */
  get name() {
    return this.element.getAttribute('data-input-name');
  }

  /**
   * Get option targets.
   * @return {Array}
   */
  get options() {
    if (!this.singleOptionListController?.hasOptionTarget) return [];

    return this.singleOptionListController.optionTargets;
  }

  /**
   * Get selected option.
   * @return {Element}
   */
  get selectedOption() {
    return this.element.querySelector('[data-components--single-option-list-target*="option"][data-selected="true"]');
  }

  /**
   * Set selected option.
   * @param {Element} option.
   */
  set selectedOption(option) {
    option.setAttribute('data-selected', 'true');
  }

  /**
   * Option change handler.
   */
  change(event, dispatchEvent = true) {
    // Set selected option.
    this.options.forEach((option) => option.setAttribute('data-selected', 'false'));
    this.selectedOption = event.currentTarget.closest('[data-components--single-option-list-target*="option"]');

    if (dispatchEvent) {
      // Dispatch change event for the current option.
      Dom.dispatchEvent(`${this.name}:change`, {
        value: event.currentTarget.value,
        option: this.selectedOption,
      });
    }
  }
}
