import { Controller } from "@hotwired/stimulus"
import Dom from 'helpers/dom';
import Spinner from 'helpers/spinner';
import Request from 'helpers/request';

export default class extends Controller {
  static targets = ['userName', 'userSelector', 'results'];
  connect() {
    console.log("statistics controller is connected")
  }
  /**
   * Get user statistics.
   */
  get(event) {
    const optionPath = this.userSelectorTarget.selectedOptions[0].dataset.path;
    if (optionPath) {
      Spinner.show(this.element);
      Request.get(optionPath)
        .then(({ response }) => {
          this.userNameTarget.textContent = response.user.email;
          this.resultsTarget.innerHTML = response.statistics;
        })
        .finally(() => {
          Spinner.remove();
        });
    } else {
      this.userNameTarget.textContent = '';
      this.resultsTarget.innerHTML = '';
    }
  }

  /**
   * Refresh specified statistic.
   */
  refresh(event) {
    Spinner.show(this.element);
    Request.get(event.currentTarget.dataset.path)
      .then(() => {
        Dom.triggerEvent(this.userSelectorTarget, 'change');
      })
      .finally(() => {
        Spinner.remove();
      });
  }
}
