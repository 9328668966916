import { Controller } from "@hotwired/stimulus"
import Dom from 'helpers/dom';
import Spinner from 'helpers/spinner';
import Request from 'helpers/request';

export default class extends Controller {
  static values = { url: String, showing: Boolean, url_amazon: String }
  static targets = ['content'];

  /**
   * Show item page.
   */
  show(event) {
    if (
      this.showingValue
      || Dom.isAnchor(event.target)
      || event.target.closest('[data-action="click->item-thumb#add"]')
    ) return;

    Spinner.show(this.element);
    this.showingValue = true;

    Request.redirect(this.urlValue, true);
  }

  /**
   * Show item page.
   */
  buyAmazon(event) {
    if (
      this.showingValue
      || Dom.isAnchor(event.target)
      || event.target.closest('[data-action="click->item-thumb#add"]')
    ) return;

    Spinner.show(this.element);
    this.showingValue = true;

    Dom.dispatchEvent('item-thumb:click', { itemElement: this.element });
    Request.redirect(this.urlAmazonValue, true);
  }

  displayLoading(event) {
    Spinner.show(this.contentTarget);
    this.contentTarget.classList.add('hidden');
  }

  displayContent() {
    Spinner.remove(this.contentTarget);
    this.contentTarget.classList.remove('hidden');
  }

  /**
   * Get cart controller.
   * @return {Controller}
   */
  get cartController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller$="header--cart"]'),
      'header--cart',
    );
  }

  /**
   * Add item to cart.
   */
  add(event) {
    if (this.element.getAttribute('data-in-cart') === 'true') return;

    this.cartController.add(event);
  }
}
