import { Controller } from "@hotwired/stimulus"
import consumer from 'channels/consumer';
import Dom from 'helpers/dom';

export default class extends Controller {
  static values = { preserveFilename: Boolean }

  connect() {
    this.channelAlive = false;
  }

  exportByForm(event) {
    event.preventDefault();

    var uuid = generateUUID();
    var form = event.target;
    var curSubmit = $("input[type=submit]", form);

    curSubmit.val('Descargando...')
    curSubmit.attr('disabled', 'disabled')
    curSubmit.attr('data-uuid', uuid);

    var formData = new FormData(form);
    var search = new URLSearchParams(formData);

    var url = form.action + '?uuid=' + uuid;

    consumer.download = consumer.subscriptions.create(
      { channel: "DownloadChannel", uuid: uuid },
      {
        connected: function() {

          $.ajax({
            type: 'POST',
            url: url,
            data: search.toString(),
          });

          showAlert('El informe será descargado en breve, no recargue o cierre la página hasta que se descargue el archivo', 'alert');
        },

        disconnected: function() {},

        received: function(data) {
          if(data.export_type == 'pdf') {
            window.location="/admin/get_tmp_file/" + data.content
          } else {
            var file = new Blob([data.content], {type: 'text/csv;charset=utf-8'});
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          }

          consumer.download.unsubscribe();
          consumer.disconnect();
          delete consumer.download;

          var title = curSubmit.data("btn-title");
          if (title === null || title === undefined) {
            title = "Generar Informe";
          }
          curSubmit.val(title)
          curSubmit.removeAttr('disabled')
          $('.alert').remove()
        }
      }
    );

    return false;
  }

  exportByLink(event) {
    event.preventDefault();
    var uuid = this.generateUUID();

    var link = this.element

    if (this.element.dataset.loadingstate) {
      link.innerHTML = this.element.dataset.loadingstate;
    }

    const url = new URL(link);
    url.searchParams.append('uuid', uuid);
    const preserveFilename = this.preserveFilenameValue;

    consumer.download = consumer.subscriptions.create(
      { channel: "DownloadChannel", uuid: uuid },
      {
        connected: function() {

          $.ajax({
            type: 'GET',
            url: url,
          });

          showAlert('El informe será descargado en breve, no recargue o cierre la página hasta que se descargue el archivo', 'alert');
        },

        disconnected: function() {},

        received: function(data) {
          if(data.export_type == 'pdf') {
            window.location="/admin/get_tmp_file/" + data.content
          } else {
            var file = new Blob([data.content], {type: 'text/csv;charset=utf-8'});
            var fileURL = URL.createObjectURL(file);
            if (!preserveFilename) {
              window.open(fileURL);
            } else {
              const anchorElement = document.createElement('a');
              anchorElement.href = fileURL;
              anchorElement.download = `${data.file_name}.${data.export_type}`;
              anchorElement.click();
              URL.revokeObjectURL(fileURL);
            }
          }

          consumer.download.unsubscribe();
          consumer.disconnect();
          delete consumer.download;
          $('.alert').remove()

          if (link.dataset.originalstate) {
            link.innerHTML = link.dataset.originalstate;
          }
          Dom.dispatchEvent('cable:received', {}, link);
        }
      }
    );
  }

  channelConnected() {
    this.channelAlive = true;
  }

  channelDisconnected() {
    this.channelAlive = false;
  }
}
