import { subscribeGetCatalogItemChannel } from "../channels/get_catalog_item_channel";
import Dom from 'helpers/dom';
import Spinner from 'helpers/spinner';

window.GetCatalogItem = function(){
  let _element, _uuid, _url, _parentEl, _inputsEl;
  let _generate = (e) => {
    e.preventDefault();
    _uuid = generateUUID();
    _element = $(e.target);
    _element.html('Buscando...').attr('disabled', 'disabled');
    _element.attr('data-uuid', _uuid);
    _url = _element.attr('href')+ '&uuid=' + _uuid
    console.log('subscribeGetCatalogItemChannel')
    console.log(_url)
    _inputsEl = {
      item_name: Dom.element('#item_name'),
      item_sell_price_admin: Dom.element('#item_sell_price_admin'),
      item_retail_price: Dom.element('#item_retail_price'),
      item_binding: Dom.element('#item_binding'),
      item_condition: Dom.element('#item_condition'),
    }
    _parentEl = Dom.element('#new_item')
    Spinner.show(_parentEl);
    subscribeGetCatalogItemChannel(_uuid, function() {
      $.get(_url);
    }, _inputsEl);
    return false;
  }
  return {
    generate: (e) => _generate(e),
  }
}();
