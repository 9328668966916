import { Controller } from "@hotwired/stimulus"
import Dom from 'helpers/dom';
import Spinner from 'helpers/spinner';

export default class extends Controller {
  connect() {
    // Remove spinner and enable click on item thumb when the page was loaded from cache or
    // accessed by navigating into the history.
    Dom.addEventListener(`${this.identifier}:pageshow`, (event) => {
      if (
        event.persisted
        || (
          window.PerformanceNavigationTiming
          && window.performance.getEntriesByType('navigation')[0].type === 'back_forward'
        )
      ) {
        this.element.querySelectorAll('[data-controller="item-thumb"] .spinner-wrapper').forEach((spinnerWrapper) => {
          const itemThumb = spinnerWrapper.parentElement;
          Spinner.remove(itemThumb);
          itemThumb.setAttribute('data-item-thumb-showing-value', 'false');
        });
      }
    }, false, window);
  }

  disconnect() {
    Dom.removeEventListener(this.identifier);
  }
}
