import SingleOptionListController from 'controllers/components/single_option_list_controller';
import Dom from 'helpers/dom';

export default class extends SingleOptionListController {
  static targets = ['creditCard', 'saveCreditCard', 'creditCardName', 'creditCardNameInput'];

  connect() {
    // Auto select payment method if there are only one.
    if (this.options.length === 1) {
      const optionInput = this.options[0].querySelector(`input[name="${this.name}"]`);
      if (!optionInput.checked) {
        optionInput.checked = true;
        Dom.triggerEvent(optionInput, 'change');

        // Auto select credit card if there are only one.
        if (this.isCreditCardSelected && this.hasCreditCardTarget && this.creditCardTargets.length === 1) {
          this.creditCardTargets[0].checked = true;
          Dom.triggerEvent(this.creditCardTargets[0], 'change');
        }
      }
    }
  }

  /**
   * Check if the selected option is credit card.
   * @return {boolean}
   */
  get isCreditCardSelected() {
    return this.selectedOption?.querySelector('input[name$="credit_card"]');
  }

  /**
   * Option change handler.
   */
  change(event) {
    super.change(event, false);

    if (
      this.hasSaveCreditCardTarget
      && this.isCreditCardSelected
      && this.saveCreditCardTarget?.type === 'checkbox'
      && this.saveCreditCardTarget.checked === false
    ) {
      this.saveCreditCardTarget.checked = true;
    } else if (this.hasSaveCreditCardTarget && !this.isCreditCardSelected) {
      this.creditCardTargets.forEach((option) => {
        option.checked = false;
      });
      this.saveCreditCardTarget.checked = false;
    }
    if (this.hasCreditCardNameTarget) {
      this.creditCardNameTarget.setAttribute('data-disabled', !this.saveCreditCardTarget.checked);
    }
    if (!this.isCreditCardSelected) {
      this.selectedOption.setAttribute('data-prev', '');
    }

    this.dispatchChange(event.currentTarget.value);
  }

  /**
   * Dispatch change event for the current option.
   */
  dispatchChange(value) {
    this.creditCardSave = this.hasSaveCreditCardTarget && this.saveCreditCardTarget.checked;
    Dom.dispatchEvent(`${this.name}:change`, {
      value,
      option: this.selectedOption,
      save: this.creditCardSave,
    });
  }

  /**
   * Save credit card change handler.
   */
  saveCreditCardChange() {
    // Auto select parent option.
    const creditCardOption = this.creditCardNameTarget.closest('[data-components--single-option-list-target="option"]');
    const creditCardOptionInput = creditCardOption.querySelector('[data-action="change->components--payment#change"]');
    if (this.selectedOption !== creditCardOption) {
      creditCardOptionInput.checked = true;
      Dom.triggerEvent(creditCardOptionInput, 'change');
    }
    this.creditCardNameTarget.setAttribute('data-disabled', !this.saveCreditCardTarget.checked);

    Dom.dispatchEvent(`${this.name}:credit_card:save:change`, {
      value: this.saveCreditCardTarget.checked,
    });
  }

  /**
   * Save credit card click handler.
   */
  creditCardClick(event) {
    if (event.currentTarget.checked && this.selectedOption?.getAttribute('data-prev') === event.currentTarget.value) {
      event.currentTarget.checked = false;
      this.selectedOption.setAttribute('data-prev', '');
      Dom.triggerEvent(event.currentTarget, 'change');
    } else {
      this.selectedOption.setAttribute('data-prev', event.currentTarget.checked ? event.currentTarget.value : '');
    }
  }

  /**
   * Credit card name change handler.
   */
  creditCardNameChange() {
    this.creditCardName = this.creditCardNameInputTarget.value;

    // Dispatch change event for the current option.
    Dom.dispatchEvent(`${this.name}:credit_card:change`, {
      value: this.creditCardName,
      option: this.selectedOption,
    });
  }

  /**
   * Saved credit card change handler.
   */
  savedCreditCardChange(event) {
    if (event.currentTarget.value !== 'new') {
      this.creditCardId = event.currentTarget.checked ? event.currentTarget.value : null;
      this.creditCardSave = false;
    } else {
      this.creditCardId = null;
      this.creditCardSave = event.currentTarget.checked;
    }

    // Dispatch change event for the current option.
    Dom.dispatchEvent(`${this.name}:credit_card:id:change`, {
      value: this.creditCardId,
      option: this.selectedOption,
      save: this.creditCardSave,
    });
  }
}
