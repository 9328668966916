import { Controller } from '@hotwired/stimulus';
import Dom from '../../helpers/dom';

export default class extends Controller {
  static values = { class: String, message: String }

  connect() {
    // Append message.
    Dom.insertHtml(this.element, `<span class="${this.classValue}">${this.messageValue}</span>`);

    // Show on click.
    this.element.addEventListener('click', this.toggle.bind(this));

    // Close on outside click.
    Dom.addEventListener(`${this.identifier}:click`, (event) => {
      if (!event.target.closest('[data-controller$="tooltip"]')) {
        this.closeAll();
      }
    });
  }

  disconnect() {
    Dom.removeEventListener(this.identifier);
  }

  /**
   * Toggle.
   */
  toggle() {
    this.element.hasAttribute('data-active') ? this.close() : this.open();
  }

  /**
   * Open.
   */
  open() {
    this.closeAll();
    this.element.setAttribute('data-active', '');
  }

  /**
   * Close.
   * @param {Element} [target=this.element] Tooltip element.
   */
  close(target = this.element) {
    target.removeAttribute('data-active');
  }

  /**
   * Close all.
   */
  closeAll() {
    document.querySelectorAll('[data-controller$="tooltip"][data-active]')
      ?.forEach(this.close);
  }
}
