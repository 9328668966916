import { Controller } from "@hotwired/stimulus"
import Dom from 'helpers/dom';
import Spinner from 'helpers/spinner';
import Request from 'helpers/request';

export default class extends Controller {
  static targets = [
    'form', 'userId', 'state', 'description', 'itemsQty', 'packagesQty', 'pickupOn', 'deliveryCompany',
    'collectionPoint', 'paymentGateway', 'paymentMethod', 'creditCardId', 'submit', 'donor', 'step2', 'city',
    'postcode', 'phone', 'hasBillingAddress'
  ];

  static values = { url: String }

  connect() {
    if (this.hasStep2Target) {
      // Shipping option change handler.
      Dom.addEventListener(`${this.identifier}:shipping:change`, (event) => {
        // Extract company and mode.
        const [company, mode] = event.detail.value?.split('~');
        this.deliveryCompanyTarget.value = company;
        this.collectionPointTarget.value = mode === 'collection_point';

        this.refresh();
      });

      // Address option change handler.
      Dom.addEventListener(`${this.identifier}:address:select`, (event) => {
        this.addressIdTarget.value = event.detail.address_id;
      });

      // Payment option change handler.
      Dom.addEventListener(`${this.identifier}:payment:change`, (event) => {
        // Extract gateway and method.
        const [paymentGateway, paymentMethod] = event.detail.value?.split('-');
        this.paymentGatewayTarget.value = paymentGateway;
        this.paymentMethodTarget.value = paymentMethod;
      });

      // Payment option credit card change handler.
      Dom.addEventListener(`${this.identifier}:payment:credit_card:id:change`, (event) => {
        this.creditCardIdTarget.value = event.detail.value;
      });
    }
  }

  disconnect() {
    Dom.removeEventListener(this.identifier);
  }

  /**
   * Get form element.
   * @return {Element}
   */
  get form() {
    return document.querySelector(`[data-${this.identifier}-target="form"]`);
  }

  /**
   * Refresh page based on selected data.
   */
  refresh() {
    const options = {
      body: new URLSearchParams(new FormData(this.formTarget)).toString(),
      accept: 'text/html',
      contentType: 'application/x-www-form-urlencoded',
      customHeaders: {
        'X-No-Layout': true,
      },
    };
    Spinner.show(this.element);
    Request.post(this.urlValue, options)
      .then((data) => {
        this.element.outerHTML = data.response;
      })
      .finally(() => {
        Spinner.remove();
      });
  }

  /**
   * Donor confirmation modal.
   */
  confirmationDonorModal(event) {
    if (this.hasDonorTarget && this.donorTarget.checked) {
      event.preventDefault();
      Spinner.show(event.currentTarget);
      Request.get('/proposals/checkout_confirmation_donor_modal', { contentType: 'text/html' })
        .then(({ response }) => {
          Dom.insertHtml('body', response);
        })
        .finally(() => {
          Spinner.remove();
        });
    }
  }

  /**
   * Enable donor confirmation modal OK button based on acceptance check.
   */
  confirmationDonorModalAcceptance(event) {
    document.querySelector('[data-action*="click->proposals--checkout#confirmationDonorModalOk"]')
      .disabled = !event.currentTarget.checked;
  }

  /**
   * Donor confirmation modal OK.
   */
  confirmationDonorModalOk() {
    this.form.submit();
  }

  /**
   * Proposal confirmation modal done.
   */
  confirmationModal(event) {
    Spinner.show(event.currentTarget);
    Request.get('/proposals/checkout_confirmation_modal', { contentType: 'text/html' })
      .then(({ response }) => {
        Dom.insertHtml('body', response);
      })
      .finally(() => {
        Spinner.remove();
      });
  }

  /**
   * Proposal confirmation modal OK.
   */
  confirmationModalOk() {
    this.form.submit();
  }
}
